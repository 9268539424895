import "./index.d";

import { sumArray, sumArrayByKey, bn, sumArrayByIndex } from "Utils/utils";

if (!Array.prototype.reduceWithKey) {
	Array.prototype.reduceWithKey = function (key: string) {
		return this.reduce((previousValue: number, currentValue: object) => {
			const _previousValue = bn(previousValue);
			const _currentValue = Object.hasOwn(currentValue, key) ? (bn(currentValue[key]).isNaN() ? 0 : bn(currentValue[key])) : 0;
			return _previousValue.plus(_currentValue).toNumber();
		}, 0);
	};
	Object.defineProperty(Array.prototype, "reduceWithKey", { enumerable: false });
}

if (!Array.prototype.sum) {
	Array.prototype.sum = function (key) {
		if (!key) return sumArray(this);
		if (typeof key == "number"){
			// select array index
			return sumArrayByIndex(this, key);
		}
		if (typeof key == "string"){
			// select object key
			return sumArrayByKey(this, key);
		}
		return 0;

		
	};
	Object.defineProperty(Array.prototype, "sum", { enumerable: false });
}

if (!Array.prototype.findOneAndReplace) {
	/**
	 * @template T
	 * @param {T[]} this
	 * @param {Partial<T>} query
	 * @param {Partial<T>} replacement
	 * @param {boolean} [options={ upsert: true }]
	 * @return {*}  {T[]}
	 */
	Array.prototype.findOneAndReplace = function <T extends Obj>(this: T[], query: Partial<T>, replacement: Partial<T>, options = { upsert: true }): T[] {
		const newArray = [...this]; // create a copy of the original array
		const index = newArray.findIndex((item) => Object.keys(query).every((key) => item[key] === query[key]));

		if (index !== -1) {
			// Replace
			newArray[index] = { ...newArray[index], ...replacement };
		} else if (options?.upsert) {
			// Insert
			newArray.push(replacement as T);
		}

		return newArray;
	};
	Object.defineProperty(Array.prototype, "findOneAndReplace", { enumerable: false });
}

function commarize(dp?: number) {
	// Alter numbers larger than 1k
	const value = bn(this).isNaN() ? 0 : bn(this).toNumber();
	if (value >= 1000) {
		const units = ["k", "M", "Bn", "Tn", "Qd", "Qt", "Sx", "Sp", "Oc", "No", "Dc"];
		// Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
		const unit = Math.floor((bn(this).toFixed(0).length - 1) / 3) * 3;
		const div = "1e" + unit;
		// Calculate the remainder;
		const num = (value / parseFloat(div)).toFixed(dp || 0);
		const unitname = units[Math.floor(unit / 3) - 1];
		// output number remainder + unitname
		return num + unitname;
	}

	// return formatted original number
	return this.toLocaleString();
}

if (!Number.prototype.commarize) {
	Number.prototype.commarize = commarize;
	Object.defineProperty(Number.prototype, "commarize", { enumerable: false });
}
if (!String.prototype.commarize) {
	String.prototype.commarize = commarize;
	Object.defineProperty(String.prototype, "commarize", { enumerable: false });
}

export {};

import { fetchBalance, getAccount } from "@wagmi/core";
import { config } from "Config/config";
import { bn } from "Utils/utils";

/**
 * @param {HexString} tokenAddress - the token
 */
const balanceOf = async (tokenAddress: HexString): Promise<WeiString> => {
	const { address } = getAccount();
	const balance = await fetchBalance({ address: address, chainId: config.WALLET_NETWORK.chainId, formatUnits: "wei", token: tokenAddress });
	return bn(balance.value).toFixed();
};

export default balanceOf;

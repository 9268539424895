import type { TransactionReceipt } from "viem";

import {
	UnwrapTxConfirmationEventName,
	UnwrapTxErrorEventName,
	UnwrapTxHashEventName,
	UnwrapTxReceiptEventName,
	WrapTxConfirmationEventName,
	WrapTxErrorEventName,
	WrapTxHashEventName,
	WrapTxReceiptEventName,
} from "./constants";

export const withdrawConfirmationEvent = (receipt: TransactionReceipt) => new CustomEvent(UnwrapTxConfirmationEventName, { detail: { receipt } });
export const withdrawErrorEvent = (error: Error) => new CustomEvent(UnwrapTxErrorEventName, { detail: error });
export const withdrawHashEvent = (hash: string) => new CustomEvent(UnwrapTxHashEventName, { detail: hash });
export const withdrawReceiptEvent = (receipt: TransactionReceipt) => new CustomEvent(UnwrapTxReceiptEventName, { detail: { receipt } });
export const depositConfirmationEvent = (receipt: TransactionReceipt) => new CustomEvent(WrapTxConfirmationEventName, { detail: { receipt } });
export const depositErrorEvent = (error: Error) => new CustomEvent(WrapTxErrorEventName, { detail: error });
export const depositHashEvent = (hash: string) => new CustomEvent(WrapTxHashEventName, { detail: hash });
export const depositReceiptEvent = (receipt: TransactionReceipt) => new CustomEvent(WrapTxReceiptEventName, { detail: { receipt } });

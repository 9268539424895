import type { TransactionReceipt } from "viem";
import { ApproveTxConfirmation, ApproveTxError, ApproveTxHash, TxConfirmationEventName, TxErrorEventName, TxHashEventName } from "./constants";

export const approveHashEvent = (hash: HexString) => new CustomEvent(ApproveTxHash, { detail: hash });
export const approveConfirmationEvent = (receipt: TransactionReceipt) => new CustomEvent(ApproveTxConfirmation, { detail: { receipt } });
export const approveErrorEvent = (error: Error) => new CustomEvent(ApproveTxError, { detail: error });

export const hashEvent = (hash: HexString) => new CustomEvent(TxHashEventName, { detail: hash });
export const confirmationEvent = (receipt: TransactionReceipt) => new CustomEvent(TxConfirmationEventName, { detail: { receipt } });
export const errorEvent = (error: Error) => new CustomEvent(TxErrorEventName, { detail: error });

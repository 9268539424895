import BigNumber from "bignumber.js";
import type { Theme } from "@mui/material";
import { SaxonData } from "./SaxonData";
import Weth from "Blockchain/erc20/weth/wethInterface";
import Erc20 from "Blockchain/erc20/erc20Interface";
import { zeroExBuyQuoteEventName, zeroExSellEventName } from "../js/hooks/swaps/use0xQuote";

export {};
type AdvancedResultsQueryOpts<T> = {
	populate?: T;
	sort?: string;
	limit?: NumStr;
	select?: string;
	page?: NumStr;
};

declare global {
	interface Array<T> {
		reduceWithKey: (key: string) => number;
		sum: (key?: string | number) => number;
		findOneAndReplace: (query: Partial<T>, replacement: Partial<T>, options?: { upsert: boolean }) => T[];
	}
	interface Number {
		commarize: (dp?: number) => string;
	}
	interface String {
		commarize: (dp?: number) => string;
	}

	interface WindowEventMap {
		[Weth.events.unwrapWeth.confirmation]: CustomEvent<{ receipt: TransactionReceipt; confirmationNumber: string }>;
		[Weth.events.unwrapWeth.error]: CustomEvent<Error>;
		[Weth.events.unwrapWeth.hash]: CustomEvent<string>;
		[Weth.events.unwrapWeth.receipt]: CustomEvent<{ receipt: TransactionReceipt }>;
		[Weth.events.wrapEth.confirmation]: CustomEvent<{ receipt: TransactionReceipt; confirmationNumber: string }>;
		[Weth.events.wrapEth.error]: CustomEvent<Error>;
		[Weth.events.wrapEth.hash]: CustomEvent<string>;
		[Weth.events.wrapEth.receipt]: CustomEvent<{ receipt: TransactionReceipt }>;
		[Erc20.events.approve.confirmation]: CustomEvent<{ receipt: TransactionReceipt; confirmationNumber: string }>;
		[Erc20.events.approve.error]: CustomEvent<Error>;
		[Erc20.events.approve.hash]: CustomEvent<string>;
		[Erc20.events.sendTransaction0x.confirmation]: CustomEvent<{ receipt: TransactionReceipt; confirmationNumber: string }>;
		[Erc20.events.sendTransaction0x.error]: CustomEvent<Error>;
		[Erc20.events.sendTransaction0x.hash]: CustomEvent<string>;
		[zeroExBuyQuoteEventName]: CustomEvent<OxQuote>;
		[zeroExSellEventName]: CustomEvent<OxQuote>;
	}
	type HexString = `0x${string}`;
	type Hex = HexString;
	type WeiString = string;
	type FN = (...args: T[]) => T;
	type aFN = (...args: T[]) => Promise<T>;
	type BN = BigNumber;
	type LooseNumber = BigNumber | number | string;
	type LooseNumberInt = BigNumber | number | string | bigint;

	type NumStr = number | string;
	type Primitive = string | number | boolean | bigint | symbol | undefined | null;

	type Obj = { [key: string]: T };
	type ObjValueType<T> = { [key: string]: T };

	type ClickEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type ChangeEvent = React.ChangeEvent<HTMLInputElement>;
	type FormEvent = React.FormEvent<HTMLFormElement>;
	type SubmitEvent = React.FormEvent<HTMLFormElement>;
	type MouseOverEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseOutEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseDownEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseUpEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseMoveEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseEnterEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseLeaveEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type MouseWheelEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>;
	type KeyDownEvent = React.KeyboardEvent<HTMLButtonElement>;
	type KeyUpEvent = React.KeyboardEvent<HTMLButtonElement>;
	type KeyPressEvent = React.KeyboardEvent<HTMLButtonElement>;
	type KeyboardEvent = React.KeyboardEventHandler<Window>;
	type FocusEvent = React.FocusEvent<HTMLButtonElement>;
	type BlurEvent = React.FocusEvent<HTMLButtonElement>;
	type SelectEvent = React.ChangeEvent<{ name?: string; value: unknown }>;

	type Event =
		| ClickEvent
		| ChangeEvent
		| FormEvent
		| SubmitEvent
		| MouseOverEvent
		| MouseOutEvent
		| MouseDownEvent
		| MouseUpEvent
		| MouseMoveEvent
		| MouseEnterEvent
		| MouseLeaveEvent
		| MouseWheelEvent
		| KeyDownEvent
		| KeyUpEvent
		| KeyPressEvent
		| FocusEvent
		| BlurEvent
		| SelectEvent;

	type EventCallback<Event> = (event: Event) => void;
	type ValueOf<Obj> = Obj[keyof Obj];
	// this type ensures that only one key is set
	type OneOnly<Obj, Key extends keyof Obj> = { [key in Exclude<keyof Obj, Key>]+?: undefined } & Pick<Obj, Key>;
	// this type ensures that only one key is set
	type OneOfByKey<Obj> = { [key in keyof Obj]: OneOnly<Obj, key> };
	// this type ensures that only one value is set
	type OneOfType<Obj> = ValueOf<OneOfByKey<Obj>>;
	type Only<T, U> = T & { [K in Exclude<keyof U, keyof T>]?: never };
	type Either<T, U> = Only<T, U> | Only<U, T>;
	type Modify<T, R> = Omit<T, keyof R> & R;
	// Example:  ConvertToBooleans<["company", "age"],"company"> = true;
	type ConvertToBooleans<T, U> = {
		[K in keyof T]: T[K] extends U ? true : false;
	};
	type IsOne<A> = A extends 1 ? true : false;

	type PickPopulated<T, U> = T extends true ? U : string;

	type AdvancedResultsQuery<Interface, PopulateOpts = string[]> = Partial<Interface> & AdvancedResultsQueryOpts<PopulateOpts>;

	type ExtractProps<TComponentOrTProps> = TComponentOrTProps extends React.ComponentType<infer TProps> ? TProps : TComponentOrTProps;

	// This type extracts the argument type from a function, index selects the argument
	type ExtractFunctionParams<T, Index = 0> = T extends (...args: infer TArgs) => unknown ? TArgs[Index] : never;

	type ExtractFunctionResult<T> = T extends (...args: unknown[]) => infer TResult ? TResult : never;

	// interface IWeb3Provider {
	// 	isMetaMask?: boolean;
	// 	connected: boolean;
	// 	enable(): Promise<void>;
	// 	connect(): Promise<void>;
	// 	isConnected(): boolean;
	// 	request(args: { readonly method: string; readonly params?: readonly unknown[] | object }): Promise<unknown>;
	// 	on(event: "accountsChanged", listener: (accounts: string[]) => void): this;
	// 	on(event: "connect", listener: (info: { chainId: number }) => void): this;
	// 	on(event: "disconnect", listener: (error: { code: number; message: string }) => void): this;
	// 	on(event: "chainChanged", listener: (chainId: number) => void): this;
	// 	on(event: "networkChanged", listener: (networkId: number) => void): this;
	// 	on(event: "message", listener: (payload: { type: string; data: unknown }) => void): this;
	// 	off(event: "accountsChanged", listener: (accounts: string[]) => void): this;
	// 	off(event: "connect", listener: (info: { chainId: number }) => void): this;
	// 	off(event: "disconnect", listener: (error: { code: number; message: string }) => void): this;
	// 	off(event: "chainChanged", listener: (chainId: number) => void): this;
	// 	off(event: "networkChanged", listener: (networkId: number) => void): this;
	// 	off(event: "message", listener: (payload: { type: string; data: unknown }) => void): this;
	// }

	interface Window {
		theme: Theme;
		SaxonData: SaxonData;
		userAddress?: string;
		user_id?: string;
		user: User;
		connectedUsers: User["id"][];
		// web3Provider?: IWeb3Provider;
		// ethereum?: IWeb3Provider;
	}
}

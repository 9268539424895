import { config } from "Config/config";
const { MAX_ALLOWED_APPROVAL } = config;
import { getAccount } from "wagmi/actions";
import { bn, asyncWrapper } from "Utils/utils";
import getClient, { getErc20Contract } from "App/src/js/utils/viem";
import { approveConfirmationEvent, approveErrorEvent, approveHashEvent } from "./events";

/**
 * @param {HexString} contractAddress - the spender of the tokens
 * @param {HexString} tokenAddress - the token to spend
 * @returns allowance value in wei
 */
type ApproveResult = {
	transactionHash: HexString;
	allowance: WeiString;
};
const approve = async (contractAddress: HexString, tokenAddress: HexString): Promise<ApproveResult> => {
	// eslint-disable-next-line no-console

	const { address: account } = getAccount();

	const client = await getClient();

	const contract = await getErc20Contract({ address: tokenAddress });

	const [simulation, simulationError] = await asyncWrapper(contract.simulate.approve([contractAddress, MAX_ALLOWED_APPROVAL], { account }));

	if (simulationError) {
		throw new Error(simulationError.message);
	}

	if (!simulation || !simulation.result || !simulation?.request) {
		throw new Error("No contract result");
	}

	const [hash, hashError] = await asyncWrapper(contract.write.approve(simulation.request.args, { account }));

	if (hashError) {
		window.dispatchEvent(approveErrorEvent(hashError));
		return;
	}

	window.dispatchEvent(approveHashEvent(hash));

	const [receipt, receiptError] = await asyncWrapper(
		client.public.waitForTransactionReceipt({
			hash,
		}),
	);

	if (receiptError) {
		window.dispatchEvent(approveErrorEvent(receiptError));
		return;
	}

	window.dispatchEvent(approveConfirmationEvent(receipt));

	const [allowance, allowanceError] = await asyncWrapper(contract.read.allowance([account, contractAddress], { account }));

	if (allowanceError) {
		throw new Error(allowanceError.message);
	}

	return {
		transactionHash: hash,
		allowance: bn(allowance).toFixed(0),
	};
};

type ApproveGasEstimateResult = {
	gasWei: BN;
	gasEth: BN;
};
const approveGasEstimate = async (contractAddress: HexString, tokenAddress: HexString): Promise<ApproveGasEstimateResult> => {
	const { address: account } = getAccount();

	const contract = await getErc20Contract({ address: tokenAddress });

	const [simulation, simulationError] = await asyncWrapper(contract.simulate.approve([contractAddress, MAX_ALLOWED_APPROVAL], { account }));

	if (simulationError) {
		throw new Error(simulationError.message);
	}

	if (!simulation || !simulation?.result || !simulation?.request) {
		throw new Error("No gas");
	}

	const [gas, gasError] = await asyncWrapper(contract.estimateGas.approve(simulation.request.args));

	if (gasError) {
		throw new Error(gasError.message);
	}

	if (!gas) {
		throw new Error("No gas");
	}

	const gasInWei = bn(gas);
	const gasInEth = bn(gasInWei).dividedBy(bn(10).pow(18));

	return {
		gasWei: gasInWei,
		gasEth: gasInEth,
	};
};

export { approve, approveGasEstimate };
export default approve;

import { fetchBalance, getAccount } from "@wagmi/core";
import { config } from "Config/config";
import { bn } from "Utils/utils";

const balance = async (): Promise<WeiString> => {
	const { address } = getAccount();
	const balance = await fetchBalance({ address: address, chainId: config.WALLET_NETWORK.chainId, formatUnits: "wei" });
	return bn(balance.value).toString();
};

export default balance;

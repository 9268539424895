import { ApproveTxConfirmation, ApproveTxError, ApproveTxHash, TxConfirmationEventName, TxErrorEventName, TxHashEventName } from "./scripts/constants";
import allowance from "./scripts/allowance";
import balanceOf from "./scripts/balanceOf";
import balance from "./scripts/balance";
import approve, { approveGasEstimate } from "./scripts/approve";
import sendTransaction0x from "./scripts/sendTransaction0x";

const erc20Interface = {
	actions: {
		balance: balance,
		balanceOf: balanceOf,
		allowance: allowance,
		approveGasEstimate: approveGasEstimate,
		approve: approve,
		sendTransaction0x: sendTransaction0x,
	},
	events: {
		sendTransaction0x: {
			confirmation: TxConfirmationEventName,
			error: TxErrorEventName,
			hash: TxHashEventName,
		},
		approve: {
			confirmation: ApproveTxConfirmation,
			error: ApproveTxError,
			hash: ApproveTxHash,
		},
	},
};

export default erc20Interface;

import "./types";
import "./main.js";

async function initPriorityPartials() {
	if (!window.user_id) {
		import("./js/pages/partials/public-nav.js").then((module) => {
			module.initPublicNav();
		});

		import("./js/pages/partials/register-btn.js").then((module) => {
			module.initRegisterBtn();
		});
	}
}
async function initApp() {
	if (window.user_id) {
		import("./js/pages/partials/network-status.tsx").then((module) => {
			module.initNetworkStatus();
		});

		import("./js/pages/partials/verification-status.js").then((module) => {
			module.initVerificationStatus();
		});

		import("./js/pages/partials/notifications.js").then((module) => {
			module.initNotifications();
		});

		import("./js/pages/partials/toast-alerts.tsx").then((module) => {
			module.initToastAlerts();
		});

		import("./js/modules/initSearch.ts").then((module) => {
			module.initSearch();
		});

		$("[data-toggle='tooltip']").tooltip();
	}

	const currentYear = new Date().getFullYear();

	// eslint-disable-next-line no-console
	console.log(
		`%c© SAXON ${currentYear}`,
		"background-color: #111c2d; color: white; font-weight: bold; " +
			"padding: 20px 30px 20px 30px; " +
			"margin: 40px 20px 10px 20px; " +
			"border: 3px solid white; " +
			"font-size: 30px; " +
			"font-family: 'Orbitron'; text-shadow: 1px 1px 3px black;",
	);

	// eslint-disable-next-line no-console
	console.log(
		"%cAll Rights Reserved",
		"background-color: #111c2d; color: white; font-weight: bold; " +
			"padding: 20px 30px 20px 30px; " +
			"margin: 0px 20px 10px 20px; " +
			"border: 3px solid white; " +
			"font-size: 14px; " +
			"font-family: 'Orbitron'; text-shadow: 1px 1px 3px black;",
	);
}

(async () => {
	initPriorityPartials();
	initApp();
})();

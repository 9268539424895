import { IOrganisation, IOtcDefaultFiatCurreny, IPrivatePlacementsDefaultFiatCurrency, IDefaultPaymentToken } from "./config.d";
import { getContractAddressesForChainOrThrow } from "@0x/contract-addresses";
import { mainnet, goerli, polygon, polygonMumbai } from "wagmi/chains";

const IS_PRODUCTION = process.env.NODE_ENV === "production";
// UNIVERSAL VARIABLES
const SAXON_LOGO = "https://storage.saxon.xyz/branding/v0/saxon-logo-dark.svg" as const;

const CRYPTO_IMAGES_URL_SVG_METAMASK = "https://storage.saxon.xyz/crypto-images/metamask.svg" as const;
const CRYPTO_IMAGES_URL_SVG_ETH = "https://storage.saxon.xyz/crypto-images/eth.svg" as const;
const CRYPTO_IMAGES_URL_SVG_POLYGON = "https://storage.saxon.xyz/crypto-images/svg/color/matic.svg" as const;
const CRYPTO_IMAGES_URL_SVG_POLYGON_WHITE = "https://storage.saxon.xyz/crypto-images/svg/white/matic.svg" as const;
const CRYPTO_IMAGES_URL_SVG_GENERIC_WHITE = "https://storage.saxon.xyz/crypto-images/svg/white/generic.svg" as const;
const CRYPTO_IMAGES_URL_SVG_GENERIC_COLOR = "https://storage.saxon.xyz/crypto-images/svg/color/generic.svg" as const;
const CRYPTO_IMAGES_BASE_URL_SVG_WHITE = "https://storage.saxon.xyz/crypto-images/svg/white/" as const;
const CRYPTO_IMAGES_BASE_URL_SVG_COLOR = "https://storage.saxon.xyz/crypto-images/svg/color/" as const;
const CRYPTO_IMAGES_BASE_URL_SXN_TOKENS = "https://storage.saxon.xyz/crypto-images/sxn-tokens/" as const;
const CRYPTO_IMAGES_BASE_URL_SXN_TOKENS_WHITE = "https://storage.saxon.xyz/crypto-images/sxn-tokens/white/" as const;
const CRYPTO_IMAGES_BASE_URL_COINGECKO = "https://storage.saxon.xyz/crypto-images/coingecko/large/" as const;
const CRYPTO_IMAGES_EXTENSION_COINGECKO = ".png" as const;

const CRYPTO_IMAGES = {
	CRYPTO_IMAGES_BASE_URL_COINGECKO,
	CRYPTO_IMAGES_BASE_URL_SVG_WHITE,
	CRYPTO_IMAGES_BASE_URL_SVG_COLOR,
	CRYPTO_IMAGES_BASE_URL_SXN_TOKENS,
	CRYPTO_IMAGES_BASE_URL_SXN_TOKENS_WHITE,
	CRYPTO_IMAGES_URL_SVG_METAMASK,
	CRYPTO_IMAGES_URL_SVG_ETH,
	CRYPTO_IMAGES_URL_SVG_POLYGON,
	CRYPTO_IMAGES_URL_SVG_POLYGON_WHITE,
	CRYPTO_IMAGES_URL_SVG_GENERIC_WHITE,
	CRYPTO_IMAGES_URL_SVG_GENERIC_COLOR,
	CRYPTO_IMAGES_EXTENSION_COINGECKO,
} as const;

const FLAGS_URL_BASE = "https://storage.saxon.xyz/flags/" as const;
const FLAGS_CIRCLE_URL_BASE = "https://storage.saxon.xyz/flags-circle/" as const;
const FLAGS_EXTENSION = ".svg" as const;
const CRYPTO_COMPARE_API_KEY = "db3e8fb271f41badf38d443870b783a3d71b4e7eacce27ee848a39c5b3fa153f" as const;
const OPENSEA_API_KEY = "f58f2313a42147e5af17fdd08a8e5809" as const;
const DEFI_PULSE_API_KEY = "7cdfe4352c85cca523aa1936dedb76a2b6b4bf5b16a69913481f6ea4ce48" as const;
const INFURA_API_KEY = "e517ddf4c33449d5882dca68307a47ec" as const;
const ETHPLORER_API_KEY = "EK-tbPp1-PzA5qEW-wodYJ" as const;
// public wallet address
const SAXON_ADVISORS_ADMIN_PUBLIC_KEY = "0xa46ebAbA72a425b42C6bdB49Afd59714fbF49c51" as const;
const ETHERSCAN_API_KEY = "EPM1Q5IM2K1DGUE8WXNAIEWIIWHENBR79S" as const;
const POLYGONSCAN_API_KEY = "93NP2SK197XCDX5SZEI3C34Y73NGKDQN4W" as const;
const ETHERSCAN_API_PARAM = `&apikey=${ETHERSCAN_API_KEY}` as const;
const POLYGONSCAN_API_PARAM = `&apikey=${POLYGONSCAN_API_KEY}` as const;
const EIP1559_ENABLED = true;

//CLOUDINARY
const CLOUDINARY_CLOUD_NAME = "saxonadvisors" as const;

const CLOUDINARY_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}` as const;

const CLOUDINARY_UPLOAD_PRESET = {
	articles: "articles",
	companies: "companies",
	tearsheets: "tearsheets",
} as const;

const CLOUDINARY_UPLOAD_ACTION = "/image/upload" as const;
const CLOUDINARY_UPLOAD_URL = `${CLOUDINARY_URL}${CLOUDINARY_UPLOAD_ACTION}` as const;

//ORGANISATION VARIABLES
const ORGANISATION_TYPES = {
	ORGANISATION_TYPES: { ADMIN: "Admin", INVESTOR: "Investor", CORPORATE: "Corporate" },
	USER_TYPES: { ADMIN: "Admin", INVESTOR: "Investor", CORPORATE: "Corporate" },
	USER_ORGANISATION_ROLES: { MEMBER: "Member", MANAGER: "Manager" },
} as const;

const ORGANISATION_ENUMS = {
	USER_ORGANISATION_ROLES_ENUM: Object.values(ORGANISATION_TYPES.USER_ORGANISATION_ROLES),
	USER_TYPES_ENUM: Object.values(ORGANISATION_TYPES.USER_TYPES),
	ORGANISATION_TYPES_ENUM: Object.values(ORGANISATION_TYPES.ORGANISATION_TYPES),
} as const;
const ORGANISATION: IOrganisation = {
	...ORGANISATION_TYPES,
	...ORGANISATION_ENUMS,
} as const;

const USER = {
	TYPES: ["Admin", "Investor", "Corporate"] as const,
};
//WEB3 wallet signature
const WEB3_SIGN_PASSWORD = "YZMMxaGzQn9oHFqa2LaCL4" as const;

const PUBLIC_NAV_ROUTES = {
	about: {
		title: "About",
		href: "/about",
	},
	advisors: {
		title: "Advisors",
		href: "/saxon-advisors",
	},
	nodes: {
		title: "Nodes",
		href: "/saxon-nodes",
	},
	ventures: {
		title: "Ventures",
		href: "/saxon-ventures",
	},
	careers: {
		title: "Careers",
		href: "https://angel.co/company/saxon-advisors",
	},
	blog: {
		title: "Blog",
		href: "/blog",
	},
	register: {
		title: "Register",
		href: "/users/register",
	},
	login: {
		title: "Login",
		href: "/users/login",
	},
} as const;

const CHAINLIST = {
	ethereum: {
		mainnet: {
			name: "Ethereum Mainnet",
			chain: "ETH",
			network: "mainnet",
			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/eth.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/eth.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/eth.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/eth.svg",
			rpc: [`https://mainnet.infura.io/v3/${INFURA_API_KEY}`, `wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}`, "https://cloudflare-eth.com"],
			rpcUrls: [`https://mainnet.infura.io/v3/${INFURA_API_KEY}`, `wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}`, "https://cloudflare-eth.com"],
			faucets: [],
			nativeCurrency: {
				name: "Ether",
				symbol: "ETH",
				decimals: 18,
				coingeckoid: "ethereum",
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
			},
			infoURL: "https://ethereum.org",
			shortName: "eth",
			id: 1,
			chainId: 1,
			chainIdHex: "0x1",
			networkId: 1,
			ens: {
				registryAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
			},
			explorers: [
				{
					name: "etherscan",
					url: "https://etherscan.io",
					standard: "EIP3091",
				},
			],
			api: {
				etherscan: "/api/ethereum/mainnet/etherscan/",
			},
			themeColor: "info-alt",
		},

		kovan: {
			name: "Ethereum Kovan",
			chain: "ETH",
			network: "kovan",
			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/eth.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/eth.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/eth.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/eth.svg",
			rpc: [
				"https://kovan.poa.network",
				"http://kovan.poa.network:8545",
				`https://kovan.infura.io/v3/${INFURA_API_KEY}`,
				`wss://kovan.infura.io/ws/v3/${INFURA_API_KEY}`,
				"ws://kovan.poa.network:8546",
			],
			nativeCurrency: {
				name: "Kovan Ether",
				symbol: "ETH",
				decimals: 18,
				coingeckoid: "ethereum",
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
			},
			shortName: "kov",
			chainId: 42,
			chainIdHex: "0x2a",
			networkId: 42,
			explorers: [
				{
					name: "kovanscan",
					url: "https://kovan.etherscan.io",
				},
			],
			api: {
				etherscan: "/api/evm/kovan/etherscan/",
			},
			themeColor: "info-alt",
		},

		ropsten: {
			name: "Ethereum Ropsten",
			chain: "ETH",
			network: "ropsten",
			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/eth.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/eth.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/eth.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/eth.svg",
			rpc: [`https://ropsten.infura.io/v3/${INFURA_API_KEY}`, `wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}`],
			nativeCurrency: {
				name: "Ropsten Ether",
				symbol: "ETH",
				decimals: 18,
				coingeckoid: "ethereum",
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
			},
			shortName: "rop",
			chainId: 3,
			chainIdHex: "0x3",
			networkId: 3,
			explorers: [
				{
					name: "ropstenscan",
					url: "https://ropsten.etherscan.io",
				},
			],
			api: {
				etherscan: "/api/evm/ropsten/etherscan/",
			},
			themeColor: "info-alt",
		},

		goerli: {
			name: "Görli",
			chain: "ETH",
			network: "goerli",
			api: {
				etherscan: "/api/evm/goerli/etherscan/",
			},
			themeColor: "info-alt",

			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/eth.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/eth.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/eth.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/eth.svg",
			rpc: [`https://goerli.infura.io/v3/${INFURA_API_KEY}`, `wss://goerli.infura.io/v3/${INFURA_API_KEY}`],
			faucets: ["http://fauceth.komputing.org?chain=5&address=${ADDRESS}", "https://goerli-faucet.slock.it?address=${ADDRESS}"],
			nativeCurrency: {
				name: "Görli Ether",
				symbol: "ETH",
				decimals: 18,
				coingeckoid: "ethereum",
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
			},
			infoURL: "https://goerli.net/#about",
			shortName: "gor",
			chainId: 5,
			chainIdHex: "0x5",
			networkId: 5,
			ens: {
				registryAddress: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e",
			},
			explorers: [
				{
					name: "etherscan-goerli",
					url: "https://goerli.etherscan.io",
					standard: "EIP3091",
				},
			],
		},
	},
	polygon: {
		mainnet: {
			name: "Polygon",
			chain: "Polygon",
			network: "mainnet",
			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/matic.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/matic.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/matic.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/matic.svg",
			rpc: [
				"https://polygon-rpc.com/",
				"wss://ws-mainnet.matic.network",
				"https://rpc-mainnet.matic.network",
				"https://rpc-mainnet.matic.quiknode.pro",
				"https://matic-mainnet.chainstacklabs.com",
			],
			faucets: [],
			nativeCurrency: {
				name: "Matic",
				symbol: "MATIC",
				decimals: 18,
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
				coingeckoid: "matic-network",
			},
			infoURL: "https://matic.network/",
			shortName: "matic",
			chainId: 137,
			chainIdHex: "0x89",
			networkId: 137,
			explorers: [
				{
					name: "polygonscan",
					url: "https://polygonscan.com",
					standard: "EIP3091",
				},
			],
			api: {
				etherscan: "/api/evm/polygon/etherscan/",
			},
			themeColor: "tertiary",
		},
		mumbai: {
			name: "Polygon",
			chain: "Polygon",
			network: "mumbai",
			iconUrls: ["https://storage.saxon.xyz/crypto-images/svg/color/matic.svg"],
			iconUrl: "https://storage.saxon.xyz/crypto-images/svg/color/matic.svg",
			iconUrlWhite: "https://storage.saxon.xyz/crypto-images/svg/white/matic.svg",
			iconUrlBlack: "https://storage.saxon.xyz/crypto-images/svg/black/matic.svg",
			rpc: ["https://rpc-mumbai.matic.today", "wss://ws-mumbai.matic.today"],
			faucets: ["https://faucet.matic.network/"],
			nativeCurrency: {
				name: "Matic",
				symbol: "MATIC",
				decimals: 18,
				address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
				coingeckoid: "matic-network",
			},
			infoURL: "https://matic.network/",
			shortName: "matic",
			chainId: 80001,
			chainIdHex: "0x13881",
			networkId: 80001,
			explorers: [
				{
					name: "polygonscan",
					url: "https://mumbai.polygonscan.com/",
					standard: "EIP3091",
				},
			],
			api: {
				etherscan: "/api/evm/mumbai/etherscan/",
			},
			themeColor: "tertiary",
		},
	},
} as const;

const OTC_DEFAULT_FIAT_CURRENCIES: IOtcDefaultFiatCurreny[] = [
	{
		value: "USD",
		label: "USD",
		decimals: 2,
	},
	{
		value: "GBP",
		label: "GBP",
		decimals: 2,
	},
	{
		value: "EUR",
		label: "EUR",
		decimals: 2,
	},
];

const PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES: IPrivatePlacementsDefaultFiatCurrency[] = [
	{
		value: "USD",
		label: "USD",
		decimals: 2,
	},
	{
		value: "GBP",
		label: "GBP",
		decimals: 2,
	},
	{
		value: "EUR",
		label: "EUR",
		decimals: 2,
	},
];

const DEFAULT_PAYMENT_TOKENS: IDefaultPaymentToken[] = [
	{
		value: "USDC",
		label: "USDC",
		symbol: "USDC",
		name: "USD Coin",
		coingeckoid: "usd-coin",
		image: `${CRYPTO_IMAGES_BASE_URL_SVG_WHITE}usdc.svg`,
		network: {
			polygon: {
				mainnet: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
				mumbai: "0x890fC75f366b82c81Ca0A9a4Ac4FcEcd05228C79", // Documentation https://ipfs.io/ipfs/QmY5rivVvH4zqULH4QgbkhCgLjyohbqWPPr1tQ4CRGVvsD
			},
			ethereum: {
				mainnet: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
				kovan: "0x75b0622cec14130172eae9cf166b92e5c112faff",
				ropsten: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
				goerli: "0x8642ebfef75de1326ccbaad7d0c7a212ae5cfd47",
				// ropsten: "0x4bb3e12Af5fDa373bc656571560DA056A1B21bfF", //dweb:/ipfs/QmUbvEW8XNp8vSyrvymopqspJLrNHaXtsC9jjizZJVyYUt
			},
		},
		decimals: 6,
	},
	{
		value: "USDT",
		label: "USDT",
		symbol: "USDT",
		name: "Tether",
		coingeckoid: "tether",
		image: `${CRYPTO_IMAGES_BASE_URL_SVG_WHITE}usdt.svg`,
		network: {
			polygon: {
				mainnet: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
				mumbai: "0xa512584Be85d7EA0b8ce78f0441bDFAA7C1F37e8", // Documentation https://ipfs.io/ipfs/QmY5rivVvH4zqULH4QgbkhCgLjyohbqWPPr1tQ4CRGVvsD
			},
			ethereum: {
				mainnet: "0xdac17f958d2ee523a2206206994597c13d831ec7",
				kovan: "0x1484a6020a0f08400f6f56715016d2c80e26cdc1",
				ropsten: "0x366EC7a35d59E27eC04614A97Cd8B45AF781f509", //dweb:/ipfs/QmUbvEW8XNp8vSyrvymopqspJLrNHaXtsC9jjizZJVyYUt
				goerli: "0x2c3f6b7291e72F31607D42d66C0cF5A9Aad371C4",
			},
		},
		decimals: 6,
	},
];

const SAXON_API_PATHS = {
	COMPANIES: {
		GET_COMPANIES: "/api/companies",
		GET_COMPANY: "/api/companies/:id",
		CREATE_COMPANY: "/api/companies",
		UPDATE_COMPANY: "/api/companies/:id",
		DELETE_COMPANY: "/api/companies/:id",
		HAS_PIPEDRIVE_DEALS: "/api/companies/:id/pipedrive",
	},
	DEALS: {
		GET_DEALS: "/api/deals",
	},
	COINS: "/api/coins", // USES ADVANCED RESULTS QUERY PARAMS
	TOKEN_LIST: "/api/coins/token_list",
	TOKEN_PRICE: "/api/coins/price",
	DATAROOM_STORAGE: "/api/uploads/v1/datarooms",
	CLOUDINARY_UPLOAD_URL: "/api/uploads/cloudinary",
	DASHBOARDS: {
		GET_USER_DASHBOARDS: "/api/dashboards/:userid",
		GET_IS_USER_DASHBOARD_FAVOURITE: "/api/dashboards/:userid/:slug",
		CREATE_USER_DASHBOARD: "/api/dashboards/:userid/:slug",
		DELETE_USER_DASHBOARD: "/api/dashboards/:userid/:slug",
	},
	USERS: {
		GET_USERS: "/api/users",
		GET_USER: "/api/users/:id",
	},
	ORGANISATIONS: {
		GET_ORGANISATIONS: "/api/organisations",
		GET_ORGANISATION: "/api/organisations/:id",
	},
} as const;

const SAXON_STORAGE_URLS = {
	MENTIONS: "https://storage.saxon.xyz/utility-data/mentions.json",
} as const;

const TYPEFORM_URLS = {
	advisors: "https://saxon-advisors.typeform.com/advisors",
	ventures: "https://saxon-advisors.typeform.com/ventures",
	nodes: "https://saxon-advisors.typeform.com/nodes",
} as const;

const Ox_QUOTE_PARAMS = {
	feeRecipient: SAXON_ADVISORS_ADMIN_PUBLIC_KEY,
	buyTokenPercentageFee: "0.05",
} as const;

const MAX_ALLOWED_APPROVAL = 115792089237316195423570985008687907853269984665640564039457584007913129639935n as const;
const WALLET_CONNECT_ID = process.env.WALLET_CONNECT_ID || "c48b94e837b422438f3bff970f32a5b2";
const OxContractAddresses = (chain: number) => {
	const { exchangeProxy, etherToken } = getContractAddressesForChainOrThrow(chain);

	return {
		Ox_ERC20PROXY_ADDRESS: exchangeProxy as HexString,
		// Ox_WETH_TRANSFORMER_ADDRESS: wethTransformer,
		ETHER_TOKEN_ADDRESS: etherToken as HexString,
	};
};

const WALLET_SIGNATURE_MESSAGE = "Welcome to Saxon! \n\nClick sign to agree to our terms of service: https://saxon.xyz/support/terms-of-use.";

const Configs = {
	PRODUCTION_ETHEREUM_1: {
		WAGMI_CHAIN: mainnet,
		MAX_ALLOWED_APPROVAL: MAX_ALLOWED_APPROVAL,
		USER: USER,
		TYPEFORM_URLS: TYPEFORM_URLS,
		PUBLIC_NAV_ROUTES: PUBLIC_NAV_ROUTES,
		CLOUDINARY_UPLOAD_PRESET: CLOUDINARY_UPLOAD_PRESET,
		CLOUDINARY_UPLOAD_URL: CLOUDINARY_UPLOAD_URL,
		SAXON_LOGO: SAXON_LOGO,
		WALLET_NETWORK: CHAINLIST.ethereum.mainnet,
		EIP1559_ENABLED: EIP1559_ENABLED,
		NATIVE_CURRENCY_SYMBOL: CHAINLIST.ethereum.mainnet.nativeCurrency.symbol,
		BLOCKCHAIN_NAME: "ethereum",
		COINGECKO_PLATFORM_NAME: "ethereum",
		EXPLORER_NAME: "Etherscan",
		ETHEREUM_NETWORK: "mainnet",
		ETHEREUM_CHAIN_ID: CHAINLIST.ethereum.mainnet.chainId,
		INFURA_API_KEY: INFURA_API_KEY,
		FLAGS_URL_BASE: FLAGS_URL_BASE,
		FLAGS_CIRCLE_URL_BASE: FLAGS_CIRCLE_URL_BASE,
		FLAGS_EXTENSION: FLAGS_EXTENSION,
		...CRYPTO_IMAGES,
		CRYPTO_COMPARE_API_KEY: CRYPTO_COMPARE_API_KEY,
		OPENSEA_API_KEY: OPENSEA_API_KEY,
		Ox_ERC20PROXY_ADDRESS: OxContractAddresses(CHAINLIST.ethereum.mainnet.chainId).Ox_ERC20PROXY_ADDRESS, // https://github.com/0xProject/protocol/blob/a99c9161007b5457d81ffe6d5a853a3d489ce30e/packages/contract-addresses/addresses.json
		ETHER_TOKEN_ADDRESS: OxContractAddresses(CHAINLIST.ethereum.mainnet.chainId).ETHER_TOKEN_ADDRESS, //
		Ox_API_BASE_URL: "/api/evm/mainnet/0x/",
		Ox_API_BASE_URL_SRA: "https://api.0x.org/sra/v4/",
		Ox_API_PATH_QUOTE: "swap/v1/quote",
		Ox_API_PATH_PRICE: "swap/v1/price",
		Ox_QUOTE_PARAMS: Ox_QUOTE_PARAMS,
		COVALENT_API_BASE_URL: "/api/evm/mainnet/covalent/",
		DEFI_PULSE_API_KEY: DEFI_PULSE_API_KEY,
		ETHPLORER_API_BASE_URL: "/api/evm/mainnet/ethplorer/",
		ETHERSCAN_API_BASE_URL: "/api/evm/mainnet/etherscan/",
		ETHERSCAN_API_URL_VERIFY: "https://api.etherscan.io/api",
		ETHPLORER_API_KEY: ETHPLORER_API_KEY,
		ETHERSCAN_ADDRESS_URL: "https://etherscan.io/address/",
		ETHERSCAN_TX_URL: "https://etherscan.io/tx/",
		ETHERSCAN_TOKEN_URL: "https://etherscan.io/token/",
		ETHERSCAN_ABI_PARAM: "module=contract&action=getabi&address=",
		ETHERSCAN_ACCOUNT_TX_PARAM: "module=account&action=txlist&address=",
		ETHERSCAN_ACCOUNT_TX_INTERNAL_PARAM: "module=account&action=txlistinternal&address=",
		ETHERSCAN_ACCOUNT_TOKEN_TX_PARAM: "module=account&action=tokentx&address=",
		ETHERSCAN_START_END_BLOCK_PARAM: "&startblock=0&endblock=99999999&sort=asc",
		ETHERSCAN_ACCOUNT_BALANCE_PARAM: "module=account&action=balance&address=",
		ETHERSCAN_ACCOUNT_TOKEN_BALANCE_PARAM: "module=account&action=tokenbalance&contractaddress=",
		ETHERSCAN_ACCOUNT_ADDRESS_PARAM: "&address=",
		ETHERSCAN_API_KEY: ETHERSCAN_API_KEY,
		ETHERSCAN_API_KEY_PARAM: ETHERSCAN_API_PARAM,
		PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.ethereum.mainnet })),
		PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES: PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES,
		OTC_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.ethereum.mainnet })),
		OTC_DEFAULT_FIAT_CURRENCIES: OTC_DEFAULT_FIAT_CURRENCIES,
		SAXON_ADVISORS_ADMIN_PUBLIC_KEY: SAXON_ADVISORS_ADMIN_PUBLIC_KEY,
		WEB3_SIGN_PASSWORD: WEB3_SIGN_PASSWORD,
		ORGANISATION: ORGANISATION,
		SAXON_API_PATHS: SAXON_API_PATHS,
		SAXON_STORAGE_URLS: SAXON_STORAGE_URLS,
		WALLET_CONNECT_ID: WALLET_CONNECT_ID,
		WALLET_SIGNATURE_MESSAGE: WALLET_SIGNATURE_MESSAGE,
	},
	DEVELOPMENT_ETHEREUM_5: {
		WAGMI_CHAIN: goerli,
		MAX_ALLOWED_APPROVAL: MAX_ALLOWED_APPROVAL,
		USER: USER,
		TYPEFORM_URLS: TYPEFORM_URLS,
		CLOUDINARY_UPLOAD_PRESET: CLOUDINARY_UPLOAD_PRESET,
		CLOUDINARY_UPLOAD_URL: CLOUDINARY_UPLOAD_URL,
		BLOCKCHAIN_NAME: "ethereum",
		COINGECKO_PLATFORM_NAME: "ethereum",
		...CRYPTO_IMAGES,
		CRYPTO_COMPARE_API_KEY: CRYPTO_COMPARE_API_KEY,
		COVALENT_API_BASE_URL: "/api/evm/goerli/covalent/",
		DEFI_PULSE_API_KEY: DEFI_PULSE_API_KEY,
		ETHPLORER_API_BASE_URL: "/api/evm/goerli/ethplorer/",
		ETHPLORER_API_KEY: ETHPLORER_API_KEY,
		ETHERSCAN_API_BASE_URL: "/api/evm/goerli/etherscan/",
		ETHERSCAN_API_URL_VERIFY: "https://api-goerli.etherscan.io/api",
		EXPLORER_NAME: "Etherscan",
		ETHEREUM_NETWORK: "goerli",
		ETHEREUM_CHAIN_ID: CHAINLIST.ethereum.goerli.chainId,
		ETHERSCAN_ADDRESS_URL: "https://goerli.etherscan.io/address/",
		ETHERSCAN_TX_URL: "https://goerli.etherscan.io/tx/",
		ETHERSCAN_TOKEN_URL: "https://goerli.etherscan.io/token/",
		ETHERSCAN_ABI_PARAM: "module=contract&action=getabi&address=",
		ETHERSCAN_ACCOUNT_TX_PARAM: "module=account&action=txlist&address=",
		ETHERSCAN_ACCOUNT_TX_INTERNAL_PARAM: "module=account&action=txlistinternal&address=",
		ETHERSCAN_ACCOUNT_TOKEN_TX_PARAM: "module=account&action=tokentx&address=",
		ETHERSCAN_START_END_BLOCK_PARAM: "&startblock=0&endblock=99999999&sort=asc",
		ETHERSCAN_ACCOUNT_BALANCE_PARAM: "module=account&action=balance&address=",
		ETHERSCAN_ACCOUNT_TOKEN_BALANCE_PARAM: "module=account&action=tokenbalance&contractaddress=",
		ETHERSCAN_ACCOUNT_ADDRESS_PARAM: "&address=",
		ETHERSCAN_API_KEY: ETHERSCAN_API_KEY,
		ETHERSCAN_API_KEY_PARAM: ETHERSCAN_API_PARAM,
		EIP1559_ENABLED: EIP1559_ENABLED,
		FLAGS_URL_BASE: FLAGS_URL_BASE,
		FLAGS_CIRCLE_URL_BASE: FLAGS_CIRCLE_URL_BASE,
		FLAGS_EXTENSION: FLAGS_EXTENSION,
		INFURA_API_KEY: INFURA_API_KEY,
		NATIVE_CURRENCY_SYMBOL: CHAINLIST.ethereum.goerli.nativeCurrency.symbol,
		OPENSEA_API_KEY: OPENSEA_API_KEY,
		Ox_QUOTE_PARAMS: Ox_QUOTE_PARAMS,
		Ox_ERC20PROXY_ADDRESS: OxContractAddresses(CHAINLIST.ethereum.goerli.chainId).Ox_ERC20PROXY_ADDRESS, // https://github.com/0xProject/protocol/blob/a99c9161007b5457d81ffe6d5a853a3d489ce30e/packages/contract-addresses/addresses.json
		ETHER_TOKEN_ADDRESS: OxContractAddresses(CHAINLIST.ethereum.goerli.chainId).ETHER_TOKEN_ADDRESS, //
		Ox_API_BASE_URL: "/api/evm/goerli/0x/",
		Ox_API_BASE_URL_SRA: "https://goerli.api.0x.org/sra/v4/",
		Ox_API_PATH_QUOTE: "swap/v1/quote",
		Ox_API_PATH_PRICE: "swap/v1/price",
		ORGANISATION: ORGANISATION,
		PUBLIC_NAV_ROUTES: PUBLIC_NAV_ROUTES,
		PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.ethereum.goerli })),
		PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES: PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES,
		OTC_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.ethereum.goerli })),
		OTC_DEFAULT_FIAT_CURRENCIES: OTC_DEFAULT_FIAT_CURRENCIES,
		SAXON_LOGO: SAXON_LOGO,
		SAXON_ADVISORS_ADMIN_PUBLIC_KEY: SAXON_ADVISORS_ADMIN_PUBLIC_KEY,
		SAXON_API_PATHS: SAXON_API_PATHS,
		SAXON_STORAGE_URLS: SAXON_STORAGE_URLS,
		WALLET_NETWORK: CHAINLIST.ethereum.goerli,
		WEB3_SIGN_PASSWORD: WEB3_SIGN_PASSWORD,
		WALLET_CONNECT_ID: WALLET_CONNECT_ID,
		WALLET_SIGNATURE_MESSAGE: WALLET_SIGNATURE_MESSAGE,
	},
	PRODUCTION_POLYGON_137: {
		WAGMI_CHAIN: polygon,
		MAX_ALLOWED_APPROVAL: MAX_ALLOWED_APPROVAL,
		USER: USER,
		TYPEFORM_URLS: TYPEFORM_URLS,
		PUBLIC_NAV_ROUTES: PUBLIC_NAV_ROUTES,
		CLOUDINARY_UPLOAD_PRESET: CLOUDINARY_UPLOAD_PRESET,
		CLOUDINARY_UPLOAD_URL: CLOUDINARY_UPLOAD_URL,
		SAXON_LOGO: SAXON_LOGO,
		BLOCKCHAIN_NAME: "polygon",
		EIP1559_ENABLED: EIP1559_ENABLED,
		COINGECKO_PLATFORM_NAME: "polygon-pos",
		ETHEREUM_NETWORK: "mainnet",
		WALLET_NETWORK: CHAINLIST.polygon.mainnet,
		NATIVE_CURRENCY_SYMBOL: CHAINLIST.polygon.mainnet.nativeCurrency.symbol,
		EXPLORER_NAME: "Polygonscan",
		ETHEREUM_CHAIN_ID: CHAINLIST.polygon.mainnet.chainId,
		INFURA_API_KEY: INFURA_API_KEY,
		...CRYPTO_IMAGES,
		CRYPTO_COMPARE_API_KEY: CRYPTO_COMPARE_API_KEY,
		OPENSEA_API_KEY: OPENSEA_API_KEY,
		Ox_QUOTE_PARAMS: Ox_QUOTE_PARAMS,
		Ox_ERC20PROXY_ADDRESS: OxContractAddresses(CHAINLIST.polygon.mainnet.chainId).Ox_ERC20PROXY_ADDRESS, // https://github.com/0xProject/protocol/blob/a99c9161007b5457d81ffe6d5a853a3d489ce30e/packages/contract-addresses/addresses.json
		ETHER_TOKEN_ADDRESS: OxContractAddresses(CHAINLIST.polygon.mainnet.chainId).ETHER_TOKEN_ADDRESS, //
		Ox_API_BASE_URL: "/api/evm/polygon/0x/",
		Ox_API_BASE_URL_SRA: "https://polygon.api.0x.org/sra/v4/",
		Ox_API_PATH_QUOTE: "swap/v1/quote",
		Ox_API_PATH_PRICE: "swap/v1/price",
		ETHPLORER_API_BASE_URL: "/api/evm/mainnet/ethplorer/",
		ETHERSCAN_API_BASE_URL: "/api/evm/polygon/etherscan/",
		ETHERSCAN_API_URL_VERIFY: "https://api.polygonscan.io/api",
		COVALENT_API_BASE_URL: "/api/evm/polygon/covalent/",
		ETHPLORER_API_KEY: ETHPLORER_API_KEY,
		ETHERSCAN_ADDRESS_URL: "https://polygonscan.com/address/",
		ETHERSCAN_TX_URL: "https://polygonscan.com/tx/",
		ETHERSCAN_TOKEN_URL: "https://polygonscan.com/token/",
		ETHERSCAN_ABI_PARAM: "module=contract&action=getabi&address=",
		ETHERSCAN_ACCOUNT_TX_PARAM: "module=account&action=txlist&address=",
		ETHERSCAN_ACCOUNT_TX_INTERNAL_PARAM: "module=account&action=txlistinternal&address=",
		ETHERSCAN_ACCOUNT_TOKEN_TX_PARAM: "module=account&action=tokentx&address=",
		ETHERSCAN_START_END_BLOCK_PARAM: "&startblock=0&endblock=99999999&sort=asc",
		ETHERSCAN_ACCOUNT_BALANCE_PARAM: "module=account&action=balance&address=",
		ETHERSCAN_ACCOUNT_TOKEN_BALANCE_PARAM: "module=account&action=tokenbalance&contractaddress=",
		ETHERSCAN_ACCOUNT_ADDRESS_PARAM: "&address=",
		ETHERSCAN_API_KEY: POLYGONSCAN_API_KEY,
		ETHERSCAN_API_KEY_PARAM: POLYGONSCAN_API_PARAM,
		DEFI_PULSE_API_KEY: DEFI_PULSE_API_KEY,
		FLAGS_URL_BASE: FLAGS_URL_BASE,
		FLAGS_CIRCLE_URL_BASE: FLAGS_CIRCLE_URL_BASE,
		FLAGS_EXTENSION: FLAGS_EXTENSION,
		PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.polygon.mainnet })),
		PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES: PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES,
		OTC_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.polygon.mainnet })),
		OTC_DEFAULT_FIAT_CURRENCIES: OTC_DEFAULT_FIAT_CURRENCIES,
		SAXON_ADVISORS_ADMIN_PUBLIC_KEY: SAXON_ADVISORS_ADMIN_PUBLIC_KEY,
		WEB3_SIGN_PASSWORD: WEB3_SIGN_PASSWORD,
		ORGANISATION: ORGANISATION,
		SAXON_API_PATHS: SAXON_API_PATHS,
		SAXON_STORAGE_URLS: SAXON_STORAGE_URLS,
		WALLET_CONNECT_ID: WALLET_CONNECT_ID,
		WALLET_SIGNATURE_MESSAGE: WALLET_SIGNATURE_MESSAGE,
	},
	DEVELOPMENT_POLYGON_80001: {
		WAGMI_CHAIN: polygonMumbai,
		MAX_ALLOWED_APPROVAL: MAX_ALLOWED_APPROVAL,
		USER: USER,
		TYPEFORM_URLS: TYPEFORM_URLS,
		PUBLIC_NAV_ROUTES: PUBLIC_NAV_ROUTES,
		CLOUDINARY_UPLOAD_PRESET: CLOUDINARY_UPLOAD_PRESET,
		CLOUDINARY_UPLOAD_URL: CLOUDINARY_UPLOAD_URL,
		SAXON_LOGO: SAXON_LOGO,
		BLOCKCHAIN_NAME: "polygon",
		EIP1559_ENABLED: EIP1559_ENABLED,
		COINGECKO_PLATFORM_NAME: "polygon-pos",
		ETHEREUM_NETWORK: "mumbai",
		NATIVE_CURRENCY_SYMBOL: CHAINLIST.polygon.mumbai.nativeCurrency.symbol,
		ETHEREUM_CHAIN_ID: CHAINLIST.polygon.mumbai.chainId,
		WALLET_NETWORK: CHAINLIST.polygon.mumbai,
		EXPLORER_NAME: "Polygonscan",
		INFURA_API_KEY: INFURA_API_KEY,
		...CRYPTO_IMAGES,
		CRYPTO_COMPARE_API_KEY: CRYPTO_COMPARE_API_KEY,
		OPENSEA_API_KEY: OPENSEA_API_KEY,
		Ox_QUOTE_PARAMS: Ox_QUOTE_PARAMS,
		Ox_ERC20PROXY_ADDRESS: OxContractAddresses(CHAINLIST.polygon.mumbai.chainId).Ox_ERC20PROXY_ADDRESS, // https://github.com/0xProject/protocol/blob/a99c9161007b5457d81ffe6d5a853a3d489ce30e/packages/contract-addresses/addresses.json
		ETHER_TOKEN_ADDRESS: OxContractAddresses(CHAINLIST.polygon.mumbai.chainId).ETHER_TOKEN_ADDRESS, //
		Ox_API_BASE_URL: "/api/evm/mumbai/0x/",
		Ox_API_BASE_URL_SRA: "https://polygon.api.0x.org/sra/v4/",
		Ox_API_PATH_QUOTE: "swap/v1/quote",
		Ox_API_PATH_PRICE: "swap/v1/price",
		ETHERSCAN_API_BASE_URL: "/api/evm/mumbai/etherscan/",
		ETHERSCAN_API_URL_VERIFY: "https://api-testnet.polygonscan.io/api",
		COVALENT_API_BASE_URL: "/api/evm/mumbai/covalent/",
		ETHPLORER_API_KEY: ETHPLORER_API_KEY,
		ETHERSCAN_ADDRESS_URL: "https://mumbai.polygonscan.com/address/",
		ETHERSCAN_TX_URL: "https://mumbai.polygonscan.com/tx/",
		ETHERSCAN_TOKEN_URL: "https://mumbai.polygonscan.com/token/",
		ETHERSCAN_ABI_PARAM: "module=contract&action=getabi&address=",
		ETHERSCAN_ACCOUNT_TX_PARAM: "module=account&action=txlist&address=",
		ETHERSCAN_ACCOUNT_TX_INTERNAL_PARAM: "module=account&action=txlistinternal&address=",
		ETHERSCAN_ACCOUNT_TOKEN_TX_PARAM: "module=account&action=tokentx&address=",
		ETHERSCAN_START_END_BLOCK_PARAM: "&startblock=1&endblock=99999999&sort=asc",
		ETHERSCAN_ACCOUNT_BALANCE_PARAM: "module=account&action=balance&address=",
		ETHERSCAN_ACCOUNT_TOKEN_BALANCE_PARAM: "module=account&action=tokenbalance&contractaddress=",
		ETHERSCAN_ACCOUNT_ADDRESS_PARAM: "&address=",
		ETHERSCAN_API_KEY: POLYGONSCAN_API_KEY,
		ETHERSCAN_API_KEY_PARAM: POLYGONSCAN_API_PARAM,
		DEFI_PULSE_API_KEY: DEFI_PULSE_API_KEY,
		FLAGS_URL_BASE: FLAGS_URL_BASE,
		FLAGS_CIRCLE_URL_BASE: FLAGS_CIRCLE_URL_BASE,
		FLAGS_EXTENSION: FLAGS_EXTENSION,
		PRIVATE_PLACEMENTS_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.polygon.mumbai })),
		PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES: PRIVATE_PLACEMENTS_DEFAULT_FIAT_CURRENCIES,
		OTC_DEFAULT_PAYMENT_TOKENS: DEFAULT_PAYMENT_TOKENS.map((item) => (item = { ...item, address: item.network.polygon.mumbai })),
		OTC_DEFAULT_FIAT_CURRENCIES: OTC_DEFAULT_FIAT_CURRENCIES,
		SAXON_ADVISORS_ADMIN_PUBLIC_KEY: SAXON_ADVISORS_ADMIN_PUBLIC_KEY,
		WEB3_SIGN_PASSWORD: WEB3_SIGN_PASSWORD,
		ORGANISATION: ORGANISATION,
		SAXON_API_PATHS: SAXON_API_PATHS,
		SAXON_STORAGE_URLS: SAXON_STORAGE_URLS,
		WALLET_CONNECT_ID: WALLET_CONNECT_ID,
		WALLET_SIGNATURE_MESSAGE: WALLET_SIGNATURE_MESSAGE,
	},
} as const;

export type Config = (typeof Configs)[keyof typeof Configs];

export const isDevelopment = (): boolean => !IS_PRODUCTION;

const getConfig = (): Config => {
	if (isDevelopment()) {
		return Configs[`DEVELOPMENT_${process.env.BLOCKCHAIN_NAME}_${process.env.CHAIN_ID}`];
	}

	return Configs[`PRODUCTION_${process.env.BLOCKCHAIN_NAME}_${process.env.CHAIN_ID}`];
};

export const config = getConfig();

export default config;

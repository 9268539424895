import {
	WrapTxConfirmationEventName,
	WrapTxErrorEventName,
	WrapTxHashEventName,
	WrapTxReceiptEventName,
	UnwrapTxConfirmationEventName,
	UnwrapTxErrorEventName,
	UnwrapTxHashEventName,
	UnwrapTxReceiptEventName,
} from "./scripts/constants";
import wrapEth from "./scripts/wrapEth";
import unwrapWeth from "./scripts/unwrapWeth";

const wethInterface = {
	actions: {
		wrapEth: wrapEth,
		unwrapWeth: unwrapWeth,
	},
	events: {
		wrapEth: {
			confirmation: WrapTxConfirmationEventName,
			error: WrapTxErrorEventName,
			hash: WrapTxHashEventName,
			receipt: WrapTxReceiptEventName,
		},
		unwrapWeth: {
			confirmation: UnwrapTxConfirmationEventName,
			error: UnwrapTxErrorEventName,
			hash: UnwrapTxHashEventName,
			receipt: UnwrapTxReceiptEventName,
		},
	},
};

export default wethInterface;

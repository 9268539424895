// @ts-check
import {default as getCurrencySymbol} from "currency-symbol-map";
import moment from "moment";
import "ramda";
import {isOdd, sortByProps, sortByProp} from "ramda-adjunct";
import {bn} from "./bignumber";
import {v4 as uuidv4} from "uuid";
import shortUuid from "short-uuid";
import Papa from "papaparse";
import BigNumber from "bignumber.js";
import type {Coins, Coin} from "App/src/types/api/Coin";
import config from "Config/config";
import Delta from "quill-delta";
import {isAddress} from "viem";

const uuid = () => uuidv4();
const suuid = () => shortUuid.generate();

export {bn, isOdd, sortByProps, sortByProp, uuid, suuid, isAddress};

export const camelCaseToCapitalizedWithSpaces = (input: string): string => {
	return input
		.replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space between lower and upper case letters
		.replace(/(\b[a-z](?!\s))/g, function (x) {
			return x.toUpperCase();
		}); // Convert first letter of each word to uppercase
};

/**
 *
 * @name getSymbolFromCurrency
 * @param {string} currencyCode eg. USD
 * @return {string | undefined}  eg. $
 * @example
 * getSymbolFromCurrency('USD') // $
 * getSymbolFromCurrency('usd') // $
 */
export const getSymbolFromCurrency = (
	currencyCode: string
): string | undefined => {
	if (!currencyCode) return undefined;
	if (typeof currencyCode !== "string") return "";
	const _currencyCode = currencyCode.toUpperCase();
	return getCurrencySymbol(_currencyCode);
};

export const transformError = (error: unknown): Error => {
	// First check if it's an AxiosError
	if (error instanceof Error) {
		// Existing checks for standard JavaScript error types...
		// Return the original error, which is already an instance of one of the Error types
		return error;
	} else if (typeof error === "string") {
		// Convert string to Error
		return new Error(error);
	} else if (
		typeof error === "object" &&
		"response" in error &&
		typeof error.response === "object" &&
		"data" in error.response &&
		typeof error.response.data === "string"
	) {
		return new Error(error.response.data);
	} else if (
		typeof error === "object" &&
		"response" in error &&
		typeof error.response === "object" &&
		"data" in error.response &&
		typeof error.response.data === "object" &&
		"message" in error.response.data &&
		typeof error.response.data.message === "string"
	) {
		return new Error(error.response.data.message);
	} else if (error && typeof error === "object") {
		// Convert object with a message property to Error
		return new Error(
			(error as {message?: string}).message || JSON.stringify(error)
		);
	} else {
		// Convert other types to Error
		return new Error("An unknown error occurred");
	}
};

type AsyncWrapperResult<T> = Promise<[T, null] | [null, Error]>;

/**
 * @template T
 * @param {Promise<T>} promise
 * @return {*}  {AsyncWrapperResult<T>}
 * @example
 * async function fetchData(): Promise<string>
 * const response = await fetch('https://jsonplaceholder.typicode.com/posts/1');
 *	const data = await response.json();
 *  return data.title;
 * }
 *
 *	async function main() {
 *	const [data,error ] = await asyncWrapper(fetchData());
 *	if (error) {
 *		console.error(error);
 * } else {
 *		console.log(data); // Output: "sunt aut facere repellat provident occaecati excepturi optio reprehenderit"
 *	}
 *	}
 *	main();
 */
export async function asyncWrapper<T>(
	promise: Promise<T>
): AsyncWrapperResult<T> {
	try {
		return [await promise, null];
	} catch (err) {
		console.error(err);
		return [null, transformError(err)];
	}
}

export const consoleLogger = (
	variableMissing: string,
	functionName: string,
	sheetName: string
): void => {
	// eslint-disable-next-line no-console
	console.log(
		`%cMissing VARIABLE: %c${variableMissing}, %cin FUNCTION: %c${functionName} %cin SHEET: %c${sheetName}`,
		"color: green; font-size: 12px;",
		"color: red; font-size: 12px;",
		"color: green; font-size: 12px;",
		"color: red; font-size: 12px;",
		"color: green; font-size: 12px;",
		"color: red; font-size: 12px;"
	);
};

/**
 * @title uniqueObjectArrayByKeys
 * @description Takes an array of objects and returns a new array with unique objects based on the keys provided
 * @param {T[]} array the array of objects
 * @param {string[]} keys the keys to use to determine uniqueness
 */
export const uniqueObjectArrayByKeys = <T>(
	array: T[],
	keys?: string[]
): T[] => {
	return array?.filter((obj, pos, arr) => {
		const _keys = keys || Object.keys(obj);
		return (
			arr
				.map((mapObj) =>
					_keys.map((key) => (mapObj as unknown)?.[key]).join("")
				)
				.indexOf(
					_keys.map((key) => (obj as unknown)?.[key]).join("")
				) === pos
		);
	});
};

/**
 *
 * @param {Obj[]} array the array to filter
 * @param {String} filterPropertyA filter key for the array
 * @param {Obj[]} itemsToKeep array of items to keeps
 * @param {String} filterPropertyB filter key for the array. optional.
 * @returns {Obj[]}
 */
export const filterItems = (
	array: Obj[],
	itemsToKeep: string[],
	filterPropertyA: string,
	filterPropertyB: string
): Obj[] => {
	if (!array) return [];
	if (!itemsToKeep) return array;
	if (!filterPropertyA) return array;
	if (!filterPropertyB) {
		return array.filter((item) =>
			itemsToKeep.includes(item[filterPropertyA])
		);
	} else {
		return array.filter((item) =>
			itemsToKeep.includes(item[filterPropertyA][filterPropertyB])
		);
	}
};

// a function that takes a string and removes any non-alphabetic characters from it
// e.g. "Hello, World!" -> "Hello World"
export const removeNonAlpha = (string: string): string => {
	return string.replace(/[^a-zA-Z ]/g, "");
};

// a function that takes a string and removes any non-alphabetic characters from it but keeps hyphens and apostrophes as well as spaces
export const removeNonAlphaAllowHypenApostrophy = (string: string): string => {
	return string.replace(/[^a-zA-Z-']/g, "");
};

type Replace = string | RegExp;
type With = string;
type ReplaceItem = [Replace, With];
/**
 * @example
 * const x = replaceString("Hello, World!", [["Hello", "Goodbye"]]);
 * console.log(x)
 * // returns 'Goodbye, World!'
 *
 * @example
 * console.log(replaceString("!", [["!", "Goodbye"]])
 * console.log(x)
 * // returns 'Goodbye'
 *
 * @example
 * const y = replaceString("Goodbye World", [["Apple", ""]]);
 * console.log(y)
 * // returns 'Goodbye World'
 */
export const replaceString = (string: string, items: ReplaceItem[]): string => {
	let result = string;

	if (items) {
		result = items.reduce((acc, item) => {
			return acc.replace(item[0], item[1]);
		}, string);
	}

	return result;
};

export const replaceNumbers = (string: string, value = ""): string => {
	const result = string.replace(/[0-9]/g, value);
	return result;
};
export const replaceSpaces = (string: string, value = ""): string => {
	const result = string.replace(/\s/g, value);
	return result;
};
export const replaceSpecialCharacters = (
	string: string,
	value = ""
): string => {
	const result = string.replace(/[^a-zA-Z0-9 ]/g, value);
	return result;
};

// a function that takes a string and removes any non-alphabetic characters from it but keeps hyphens and apostrophes as well as spaces
export const removeNonAlphaAllowHypenApostrophySpaces = (
	string: string
): string => {
	return string.replace(/[^a-zA-Z- ']/g, "");
};

export const isSafeStringWithNoNumbersOrSpaces = (string: string): boolean => {
	return !/[^a-z]/i.test(string);
};
export const isSafeStringWithNoNumbers = (string: string): boolean => {
	const RegExpression = /^[a-zA-Z[\]()\s]*$/;
	return RegExpression.test(string);
};

export const getSafeString = (string: string): string => {
	return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const largestValueInArray = (array: number[]): number => {
	return Math.max(...array);
};

export const dateFormat = (date: number | string): string => {
	const x = moment(date).format("LL");
	return x;
};

/**
 * @title reducer
 * @description Sums up the values in an array
 * @param {number} previousValue
 * @param {number} currentValue
 * @returns {number} total of array
 * @example
 * const array1 = [1, 2, 3, 4];
 * console.log(array1.reduce(reducer));
 * // expected output: 10
 */
export const reducer = (
	previousValue: LooseNumber,
	currentValue: LooseNumber
): number => {
	const _previousValue = bn(previousValue);
	const _currentValue = bn(currentValue).isNaN() ? 0 : bn(currentValue);
	const sum: number = _previousValue.plus(_currentValue).toNumber();
	return sum;
};

/**
 * @title sumArray
 * @description Sums up the values in an array
 * @param {ArrLooseNumber} array
 * @returns {number} total of array
 * @example
 * const array1 = [1, 2, 3, 4];
 * console.log(array1.reduce(reducer));
 * // expected output: 10
 */
export const sumArray = (array: LooseNumber[]): number => {
	if (!array) return 0;
	return array.reduce(reducer, 0) as number;
};

/**
 * @title sumArrayByKey
 * @description Sums up the values in an array by key
 * @param {Obj[]} array
 * @param {string} key
 * @return {number}
 */
export const sumArrayByKey = (array: Obj[], key: string): number => {
	if (!array) return 0;
	const _array = array.filter((item) => item[key]);
	const _mappedArray = _array.map((item) => item[key]);
	return sumArray(_mappedArray);
};

/**
 * @title sumArrayByIndex
 * @description Sums up the values in an array of arrays by index
 * @param {[][]} array
 * @param {number} key
 * @return {number}
 */
export const sumArrayByIndex = (array: [][], key: number): number => {
	if (!array) return 0;
	if (!key) return 0;
	const _array = array.filter((item) => item[key]);
	const _mappedArray = _array.map((item) => item[key]);
	return sumArray(_mappedArray);
};

/**
 * @title arraysMatch
 * @description Checks if two arrays are the same
 * @param arr1 - first array
 * @param arr2 - second array
 * @return {boolean}
 * @example
 * const array1 = [1, 2, 3, 4];
 * const array2 = [1, 2, 3, 4];
 * console.log(arraysMatch(array1, array2));
 * // expected output: true
 */
export function arraysMatch<T>(arr1: T[], arr2: T[]): boolean {
	// Check if the arrays are the same length
	const _arr1 = JSON.stringify(arr1);
	const _arr2 = JSON.stringify(arr2);

	if (_arr1.length !== _arr2.length) return false;

	// Check if all items exist and are in the same order
	for (let i = 0; i < _arr1.length; i++) {
		if (_arr1?.[i] !== _arr2?.[i]) return false;
	}
	// Otherwise, return true
	return true;
}

/**
 * @title areObjectsEqual
 * @description Shallow comparison of any number of objects to see if are the same as the first one.
 * @return {boolean}
 * @example
 * const obj1 = { name: "John", age: 30 };
 * const obj2 = { name: "John", age: 30 };
 * const obj3 = { name: "John", age: 30 };
 * console.log(areObjectsEqual(obj1, obj2, obj3));  // Outputs: true
 * const obj4 = { name: "John", age: 31 };
 * console.log(areObjectsEqual(obj1, obj2, obj3, obj4));  // Outputs: false
 */
export const areObjectsEqual = (...objects: Obj[]): boolean => {
	const baseObjectKeys = Object.keys(objects[0]);

	for (const obj of objects.slice(1)) {
		const objKeys = Object.keys(obj);

		if (objKeys.length !== baseObjectKeys.length) {
			return false;
		}

		for (const key of baseObjectKeys) {
			if (objects[0][key] !== obj[key]) {
				return false;
			}
		}
	}

	return true;
};

/**
 * @title groupBy
 * @description groups an array of objects by a property
 * @param {Obj[]} arrayOfObjects
 * @param {string} property
 * @return {Obj}
 * @example
 * const array = [
 * { name: "John", age: 21 },
 * { name: "Jane", age: 21 },
 * { name: "Jack", age: 22 },
 * { name: "Jill", age: 22 },
 * ];
 * const grouped = groupBy(array, "age");
 * console.log(grouped);
 * // { 21: [{ name: "John", age: 21 }, { name: "Jane", age: 21 }], 22: [{ name: "Jack", age: 22 }, { name: "Jill", age: 22 }] }
 */
export const groupBy = (arrayOfObjects: Obj[], property: string): Obj => {
	return arrayOfObjects.reduce((acc, obj) => {
		const key = obj[property];
		const curGroup = acc[key] ?? [];

		return {...acc, [key]: [...curGroup, obj]};
	}, {});
};

/**
 *
 * @param {LooseNumberInt} value - the quantity of tokens
 * @param {LooseNumberInt} [decimals] - decimals of the token default = 18
 * @param {boolean} [makeLarger] - default = false. If true, the value will be multiplied by 10^decimals. If false, the value will be divided by 10^decimals
 * @example formatTokenValue(value: 1.23, decimals: 3,makeLarger:true) => '1230'
 * @example formatTokenValue(value: 1.23, decimals: 3,makeLarger:false) => '0.00123'
 * @returns {BigNumber} - the value as a BigNumber
 */

export const formatTokenValue = (
	value: LooseNumberInt,
	decimals: LooseNumberInt = 18,
	makeLarger = false
): BigNumber => {
	const dec = bn(decimals).toNumber();
	if (makeLarger) {
		const val = bn(value).isNaN() ? bn(0) : bn(value).shiftedBy(dec);
		return val;
	} else {
		const val = bn(value).isNaN() ? bn(0) : bn(value).shiftedBy(dec * -1);
		return val;
	}
};

interface EtherDenomination {
	wei: number;
	kwei: number;
	mwei: number;
	gwei: number;
	szabo: number;
	finney: number;
	ether: number;
}
/**
 * @title formatEthValue
 * @description - converts a value from one denomination to another
 * @returns {BigNumber} - the value as a BigNumber
 */
export const formatEthValue = (
	value: LooseNumberInt,
	from: keyof EtherDenomination,
	to: keyof EtherDenomination
): BigNumber => {
	const EtherDenominations: EtherDenomination = {
		wei: 0,
		kwei: 3,
		mwei: 6,
		gwei: 9,
		szabo: 12,
		finney: 15,
		ether: 18,
	};
	const fromDenomination = EtherDenominations[from]; // 18
	const toDenomination = EtherDenominations[to]; // 0
	const dec = bn(fromDenomination).minus(toDenomination).toNumber(); // 18
	const val = bn(value).isNaN()
		? bn(0).shiftedBy(dec)
		: bn(value).shiftedBy(dec);
	return val;
};

/**
 *
 * @param {LooseNumber} number1
 * @param {LooseNumber} number2
 */
export const percentDifference = (
	number1: LooseNumber,
	number2: LooseNumber
): string => {
	const top = bn(number1);
	const bottom = bn(number2);
	const resp = bottom.minus(top).dividedBy(top).multipliedBy(100).toFormat(2);
	return resp;
};

/**
 * @title discount
 * @description calculates the discount between two numbers
 * @param {LooseNumber} number1
 * @param {LooseNumber} number2
 * @returns {string} discount
 */
export const discount = (
	number1: LooseNumber,
	number2: LooseNumber
): string => {
	//market price
	const a = bn(number1);
	//tranche price
	const b = bn(number2);
	if (a.isGreaterThan(b)) {
		// market divided by tranche
		const c = b.dividedBy(a);
		// market divided by tranche
		const d = bn(1).minus(bn(c));
		const res = d.multipliedBy(bn(100)).toFormat(2);
		return res;
	} else {
		return bn(0).toFormat(2);
	}
};

export const removeCommas = (string: string): string => {
	return string.replace(/,/g, "");
};

/**
 * @export
 * @param {string} string string to be parsed
 * @param {string} replacement character to replace underscore
 * @returns {string} string with underscores replaced
 */
export const replaceUnderscore = (
	string: string,
	replacement: string
): string => {
	const char = replacement || " ";
	const pattern = /_/g;
	const x = string.replace(pattern, char);
	return x;
};

/**
 * @title formatUnit
 * @param {LooseNumber} value - the value to be formatted
 * @param {LooseNumber} [dp] - the number of decimal places to be displayed
 * @returns {string} formatted value
 * @example formatUnit(123456.789, 2) => '123.79k'
 * @example formatUnit(123456.789, 0) => '124k'
 * @example formatUnit(123456545.789) => '123.46m'
 * @example formatUnit(123456545.789, 0) => '123m'
 */
export const formatUnit = (value: LooseNumber, dp?: number): string => {
	const isNegative = bn(value).isLessThan(0);
	const _dp = !dp ? bn(value).abs().toString().split(".")?.[1]?.length : dp;
	const _value = bn(value).abs().toFixed(_dp);
	if (isNegative) {
		return String("-").concat(_value.commarize(dp));
	}
	const result = bn(_value).isNaN() ? "-" : _value.commarize(dp);

	return result;
};

export const formatMultiple = (value: LooseNumber, dp?: number): string => {
	const _value = bn(value).isNaN() ? "-" : bn(value).toFixed(dp) + "x";
	return _value;
};

/**
 * @title formatCurrency
 * @param {LooseNumber} number
 * @param {LooseNumber} [decimalLength] default 2
 * @param {string} [ISOcurrency] default "USD"
 * @returns {string} formatted currency
 * @example formatCurrency(123456.789, 2, "USD") => "$123,456.79"
 * @returns {string} formatted currency
 */
export const formatCurrency = (
	number: LooseNumber,
	decimalLength: LooseNumber = 2,
	ISOcurrency = "USD"
) => {
	const dec = bn(decimalLength).isNaN() ? 2 : bn(decimalLength).toNumber();
	const num = bn(number).isNaN()
		? bn(0).toFormat(dec)
		: bn(number).toFormat(dec);
	const symbol = ISOcurrency
		? getSymbolFromCurrency(ISOcurrency.toUpperCase())
		: getSymbolFromCurrency("USD");
	return symbol + num;
};

/**
 *
 *
 * @param {number} bytes
 * @param {number} [decimals=2]
 * @return {string}  {string}
 * @example
 * formatBytes(123456789) // => "117.74 MB"
 */
export const formatBytes = (bytes: number, decimals = 2): string => {
	const _bytes = bn(bytes);
	if (_bytes.eq(0)) return "0 Bytes";

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(_bytes.toNumber()) / Math.log(k));

	return parseFloat(_bytes.div(bn(k).pow(i)).toFixed(dm)) + " " + sizes[i];
};

export const toMoneyString = (numericValue: number, decimals = 2): string => {
	return numericValue.toLocaleString("en-US", {
		style: "currency",
		currency: "USD",
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	});
};

/**
 * @deprecated - use formatCurrency instead
 * @alias formatCurrency
 * @param {LooseNumber} number
 * @param {LooseNumber} [decimalLength] default 2
 * @param {string} [ISOcurrency] default "USD"
 * @returns {string} formatted currency
 * @example formatCurrency(123456.789, 2, "USD") => "$123,456.79"
 * @returns {string} formatted currency
 */
export const currencyFormat = formatCurrency;

/**
 * @title formatPercent
 * @param {LooseNumber} numb
 * @param {LooseNumber} [dp] default 2
 * @returns {string} formatted percent string
 * @example formatPercent(1, 2) => "100.00%"
 * @returns {string} formatted percent string
 */
export const formatPercent = (
	num: LooseNumber,
	dp: LooseNumber = 2
): string => {
	const dec = bn(dp).isNaN() ? 2 : bn(dp).toNumber();
	const number = bn(num).toFormat(dec);
	return number + "%";
};

/**
 * @deprecated - use formatPercent instead
 * @alias percentFormat
 * @param {number || string || BigNumber} number
 * @param {number || string } [decimalLength] default 2
 * @returns {string} formatted percent string
 * @example percentFormat(1, 2) => "100.00%"
 * @returns {string} formatted percent string
 */
export const percentFormat = formatPercent;

/**
 * @title formatNumber
 * @param {LooseNumber} num the number
 * @param {LooseNumber} decimalLength number of decimals
 * @returns {string} number
 */
export const formatNumber = (
	num: LooseNumber,
	decimalLength: LooseNumber = 2
): string => {
	const dec = bn(decimalLength).isNaN() ? 2 : bn(decimalLength).toNumber();
	const number = bn(num ? num : 0).toFormat(dec);
	return number;
};

/**
 *
 * @deprecated - use formatNumber instead
 * @alias formatNumber
 * @param {LooseNumber} num the number
 * @param {LooseNumber} decimalLength number of decimals
 * @returns {string} number
 */
export const numberFormat = formatNumber;

export const sanitizeHTML = (text: string): string => {
	const element = document.createElement("div");

	element.innerText = text;
	return element.innerHTML;
};

/**
 * @desc Capitalises sentence and exclude letters that are already capitalised.
 * @example
 * console.log(capitaliseSentenceExcludeUppercase("Hello World").length); // "Hello World"
 * console.log(capitaliseSentenceExcludeUppercase("hELLO wORLD").length); // "HELLO WORLD"
 * console.log(capitaliseSentenceExcludeUppercase("hello world").length); // "Hello World"
 * console.log(capitaliseSentenceExcludeUppercase("hello world ").length); // "Hello World "
 */

export const capitaliseSentenceExcludeUppercase = (sentence: string) => {
	if (!sentence || sentence === "" || sentence === " ") return sentence;
	// Check if the first letter of each word is upper-case
	const hasCapitalAtStartOfWords = sentence
		.split(" ")
		.every((word) => word[0] === word[0]?.toUpperCase());

	if (hasCapitalAtStartOfWords) {
		return sentence;
	} else {
		// Capitalise the first letter of every word
		return sentence.replace(/(^|\s)\S/g, function (ch) {
			return ch.toUpperCase();
		});
	}
};

/**
 * @desc Capitalises sentence with all other text lowercase.
 */
export const capitaliseSentence = (
	sentence: string,
	seperator = " "
): string => {
	if (!sentence) return "";
	const mySentence = sentence?.toLowerCase();
	const separationChar = seperator;
	const words = mySentence.split(separationChar);

	const formatted = words
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(" ");

	return formatted;
};
/**
 * @desc Capitalises sentence with all other text lowercase.
 * @param {string} str
 * @return {string}
 */
export const capitaliseSentenceRegex = (str: string): string => {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
	});
};

/**
 * @desc Capitalises first letter.
 * @param {string} str
 * @return {string}
 */
export const capitaliseStartOfSentence = (str: string): string => {
	return str.replace(/\w\S*/, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substring(1);
	});
};

/**
 * @desc This function assumes that sentences are always separated by one of these three punctuation marks (., ?, !). If sentences could also be separated by other characters, the function would need to be adjusted.
 * @param {string} str
 * @return {string}
 * @example
 * let paragraph = 'this is a test. another test? yes, another one! yet another test.';
 * let result = capitalizeFirstLetterOfSentences(paragraph);
 * console.log(result); // Output: "This is a test. Another test? Yes, another one! Yet another test."
 */
export const capitaliseFirstLetterOfSentences = (paragraph: string): string => {
	return paragraph.replace(/(^\s*\w|[.!?]\s*\w)/g, function (char) {
		return char.toUpperCase();
	});
};

/**
 * @param {string} word
 */
export const capitaliseWord = (word: string): string => {
	if (!word) return word;
	return word[0].toUpperCase() + word.substring(1);
};

export const toUpperCase = (string: string): string => {
	if (!string) return string;
	return string.toUpperCase();
};

export const toCamelCase = (str: string): string => {
	return str
		.toLowerCase()
		.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

function log(value: string, msg: string): void {
	const toast = document.getElementById("toast");
	if (toast.hidden) toast.textContent = msg;
	else toast.textContent += "\n" + value;
	toast.className = String(value).match(/error/i) ? "error" : "";
	toast.hidden = false;
	const timer = setTimeout(() => {
		toast.hidden = true;
	}, 3000);
	clearTimeout(timer);
}

/**
 *
 *
 * @param {string} content the text to be copied
 * @param {string} msg the message to confirm success
 */
export const setClipboard = async (content: string, msg: string) => {
	log(content, msg);
	try {
		await navigator.clipboard.writeText(content);
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error("Failed to copy: ", err);
	}
};

/**
 * @title convertValueToHMS
 * @desc Converts a value to a string in the format HH:MM:SS
 * @param {LooseNumber} value in seconds, max 86400
 * @return {string} HH:MM:SS e.g. 11:29:31
 */
export const convertValueToHMS = (value: LooseNumber): string => {
	const sec = bn(value).toNumber(); // convert value to number if it's string
	const hours = Math.floor(sec / 3600); // get hours
	const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
	const seconds = sec - hours * 3600 - minutes * 60; //  get seconds
	// add 0 if value < 10; Example: 2 => 02
	let time = "";
	time += hours < 10 ? "0" + hours + ":" : hours + ":";
	time += minutes < 10 ? "0" + minutes + ":" : minutes + ":";
	time += seconds < 10 ? "0" + seconds : seconds;
	return time; // Return is HH : MM : SS
};
/**
 * @title convertValueToMS
 * @desc Converts a value to a string in the format MM:SS
 * @param {LooseNumber} value in seconds, max 3600
 * @return {string} MM:SS e.g. 29:31
 */
export const convertValueToMS = (value: LooseNumber): string => {
	const sec = bn(value).toNumber(); // convert value to number if it's string
	const hours = Math.floor(sec / 3600); // get hours
	const minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
	const seconds = sec - hours * 3600 - minutes * 60; //  get seconds
	let time = "";
	// add 0 if value < 10; Example: 2 => 02
	time += minutes < 10 ? "0" + minutes + ":" : minutes + ":";
	time += seconds < 10 ? "0" + seconds : seconds;
	return time; // Return is MM : SS
};

/**
 * @title isEmail
 * @desc checks if a string is a valid email address
 * @param {string} email
 * @return {boolean}
 */
export const isEmail = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

/**
 * @title keepValidEmailChars
 * @desc removes all characters from a string that are not valid in an email address
 * @param {string} email
 * @return {string}  {string}
 */
export const keepValidEmailChars = (email: string): string => {
	return email.replace(/[^a-zA-Z0-9@.]/g, "");
};

/**
 *
 * @description a function that converts a json array of arrays to a csv file and downloads it
 * @param data
 * @param {string} fileName eg "myFile.csv"
 */
export const papaSaveDataAsCsv = (data: unknown[], filename: string): void => {
	const csvData = Papa.unparse(data);
	const blob = new Blob([csvData], {type: "text/csv;charset=utf-8;"});
	const link = document.createElement("a");
	link.href = URL.createObjectURL(blob);
	link.download = filename ? filename : "data.csv";
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

/**
 * @description a function that converts a json array of arrays to a csv file and downloads it
 * @param data
 * @param {string} fileName eg "myFile.csv"
 */
export const saveDataAsCSV = (data: BlobPart, fileName: string): void => {
	const a = document.createElement("a");
	a.style.display = "none";
	document.body.appendChild(a);

	const blob = new Blob([data], {type: "text/csv"});
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

/**
 * @description a function that converts a json array of arrays to an xlsx file and downloads it
 * @param {any} data
 * @param {string} fileName eg "myFile.xlsx"
 */
export const saveDataAsExcel = (data: BlobPart, fileName: string): void => {
	const a = document.createElement("a");
	a.style.display = "none";
	document.body.appendChild(a);
	const blob = new Blob([data], {type: "application/vnd.ms-excel"});
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
};

/**
 *
 * @name urlParamsReplace
 * @description a function that replaces the url params
 * @param {string} pathname
 * @param {string|string[]} params eg ["id", "name"]
 * @param {*,|*[]} values eg [50454, "James"]
 * @return {string} pathname with params replaced with values
 */
export const urlParamReplace = (
	pathname: string,
	params: string | string[],
	values: NumStr | NumStr[]
): string => {
	if (!pathname) {
		return "";
	}
	if (!params || !values) {
		return pathname;
	}

	if (Array.isArray(params) && !Array.isArray(values)) {
		return pathname;
	}

	if (
		Array.isArray(params) &&
		Array.isArray(values) &&
		params.length !== values.length
	) {
		return pathname;
	}

	// if (!window.URLPattern) {
	let newPath = pathname;

	if (Array.isArray(params) && Array.isArray(values)) {
		for (let i = 0; i < params.length; i++) {
			if (pathname.includes(params[i])) {
				const param = params[i];

				const value = values[i];
				newPath = newPath.replace(`:${param}`, `${value}`);
			} else {
				newPath = pathname;
			}
		}
	}
	if (!Array.isArray(params) && !Array.isArray(values)) {
		if (pathname.includes(params)) {
			newPath = newPath.replace(`:${params}`, `${values}`);
		} else {
			newPath = pathname;
		}
	}
	return newPath;
};

/**
 *
 * @name queryString
 * @description a function that replaces the url params
 * @param {Obj} params eg {id: 50454, name: "James"}
 * @param {string} [prefix] eg "?"
 * @return {string}
 * @example
 * queryString({id: 50454, name: "James"}) // ?id=50454&name=James
 */
export const queryString = (params: Obj, prefix = "?"): string => {
	if (!params) {
		return "";
	}

	const keys = Object.keys(params);

	const query = keys
		.map((key) => {
			const value = params[key];
			return `${key}=${value}`;
		})
		.join("&");

	return prefix + query;
};

/**
 *
 * @name pick
 * @param {Obj} obj the object to pick from
 * @param {string | string[]} keys the keys to pick eg "person.name" or ["person.name", "age"]
 * @return {Obj} object with only the keys specified
 */
export const pick = (obj: Obj, keys: string | string[]): Obj => {
	const isString = typeof keys === "string";

	const _keys = isString ? [keys] : keys;

	const isArr = Array.isArray(keys);
	if (!isArr && !isString) {
		return obj;
	}

	return _keys.reduce((acc, key) => {
		const splitTest = key.includes(".");
		if (splitTest) {
			const last = key.split(".").pop();
			const splitKey = key.split(".");
			let newObj = obj;
			for (let i = 0; i < splitKey.length; i++) {
				if (!newObj?.[splitKey[i]]) {
					newObj = null;
				} else {
					const splitKeyItem = splitKey[i];
					newObj = newObj[splitKeyItem];
				}
			}

			acc[last] = newObj;
		} else {
			acc[key] = obj[key];
		}

		return acc;
	}, {});
};

export const addressShortener = (address: string): string => {
	if (
		!address ||
		typeof address !== "string" ||
		address.length < 10 ||
		address.length > 42
	) {
		return address;
	}
	const first = address.slice(0, 6);
	const last = address.slice(-4);
	return `${first}...${last}`;
};

export const isValidUrl = (url: string): boolean => {
	const urlRegex = /^https?:\/\/([a-z0-9-]+\.)+[a-z]{2,6}([/?].*)?$/i;
	return urlRegex.test(url);
};

export const isValidLinkedInUrl = (url: string): boolean => {
	if (!url) {
		return false;
	}

	try {
		const regex = new RegExp(
			/^https:\/\/(www\.)?linkedin\.com\/in\/([A-Za-z0-9_-]|(%[0-9A-Fa-f]{2}))+\/?$/i
		);

		return regex.test(url);
	} catch (e) {
		// Here you could handle or throw the error.
		return false;
	}
};

export const removeQueryString = (url: string): string => {
	if (!url) {
		return "";
	}
	const split = url.split("?");
	return split[0];
};

interface CloudinaryTransformationOptions {
	width?: number;
	height?: number;
	crop?:
		| "scale"
		| "fit"
		| "limit"
		| "mfit"
		| "fill"
		| "lfill"
		| "pad"
		| "lpad"
		| "mpad"
		| "crop"
		| "thumb";
	quality?: number;
	format?:
		| "jpg"
		| "png"
		| "gif"
		| "webp"
		| "webm"
		| "mp4"
		| "svg"
		| "ico"
		| "pdf"
		| "eps"
		| "psd"
		| "hdr"
		| "flif"
		| "jp2"
		| "jxr"
		| "wdp"
		| "hdp"
		| "bpg"
		| "tiff";
}

export const addTransformToCloudinaryUrl = (
	url: string,
	options: CloudinaryTransformationOptions
) => {
	const {width, height, crop, quality, format} = options;
	const transforms = [];

	if (width) {
		transforms.push(`w_${width}`);
	}
	if (height) {
		transforms.push(`h_${height}`);
	}
	if (crop) {
		transforms.push(`c_${crop}`);
	}

	if (quality) {
		transforms.push(`q_${quality}`);
	}

	if (transforms.length > 0) {
		const urlParts = url.split("/upload/");
		const urlPart1 = urlParts[0];
		const urlPart2 = urlParts[1];

		const _url = `${urlPart1}/upload/${transforms.join(",")}/${urlPart2}`;

		const extension = _url.split(".").pop();

		if (format && format !== extension) {
			return _url?.replace(extension, format)?.trim();
		}

		return _url;
	}
};

export const isValidTelegramHandle = (handle: string): boolean => {
	if (!handle) {
		return false;
	}
	const regex = new RegExp(/@([A-Za-z0-9_]{1,15})/);
	return regex.test(handle);
};

export const convertTelegramHandleToLink = (handle: string): string => {
	if (!handle) {
		return "";
	}
	const regex = new RegExp(/@([A-Za-z0-9_]{1,15})/);
	if (!regex.test(handle)) {
		return "";
	}
	const split = handle.split("@");
	return `https://t.me/${split[1]}`;
};

export const prettyUrl = (url: string): string => {
	if (!url) {
		return "";
	}
	return new URL(url).hostname;
};

/**
 * @title getBase64FromImageUrl
 * @description a function that returns the base64 of an image
 * @param {string} url
 * @return {Promise<string>} base64
 */
export const getBase64FromImageUrl = async (url: string): Promise<string> => {
	const storage = window.sessionStorage.getItem("base64Images");
	if (storage) {
		const parsedStorage = JSON.parse(storage);
		if (parsedStorage[url]) {
			return parsedStorage[url];
		}
	}
	if (!storage) {
		window.sessionStorage.setItem("base64Images", JSON.stringify({}));
	}

	const parsedStorage = JSON.parse(
		window.sessionStorage.getItem("base64Images")
	);

	const response = await fetch(url);
	if (!response.ok) {
		parsedStorage[url] = null;
		window.sessionStorage.setItem(
			"base64Images",
			JSON.stringify(parsedStorage)
		);
		return null;
	}
	const blob = await response.blob();
	const result = new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.readAsDataURL(blob);
		reader.onerror = reject;
	});

	parsedStorage[url] = (await result) as string;

	window.sessionStorage.setItem(
		"base64Images",
		JSON.stringify(parsedStorage)
	);

	return result as Promise<string>;
};

const coins: Coins = window.sessionStorage.getItem("coins")
	? JSON.parse(window.sessionStorage.getItem("coins"))
	: [];

type getCoinGeckoIdIdentifier = "coingeckoid" | "address" | "symbol" | "name";
type getCoinGeckoIdParams = {
	[identifier in getCoinGeckoIdIdentifier]?: string;
};

/**
 * @name getCoinGeckoId
 * @summary a function that returns the coingecko id of a coin
 * @description MAINNET for each blockchain ONLY!
 * @param {getCoinGeckoIdParams} params the address of the coin or the symbol
 * @return {string}  the coingecko id of the coin or null
 
 */

export const getCoinGeckoId = (params: getCoinGeckoIdParams): string => {
	const {address, symbol, name, coingeckoid} = params;
	if (coins.length === 0) {
		return null;
	}
	if (!address && !symbol && !coingeckoid && !name) {
		return null;
	}
	if (coingeckoid) {
		const coin = coins.find((item) => item.coingeckoid === coingeckoid);
		if (coin) {
			return coin.coingeckoid;
		}
	}
	if (address) {
		const coin = coins
			.filter((c) => !!c.platforms)
			.find(
				(coin: Coin) =>
					coin?.platforms[config.BLOCKCHAIN_NAME.toLowerCase()] ===
					address.toLowerCase()
			);
		if (coin && coin.coingeckoid) {
			return coin.coingeckoid;
		}
	}

	let result = null;

	if (symbol && name) {
		const coin = coins.find(
			(coin: Coin) =>
				coin?.symbol?.toLowerCase() === symbol?.toLowerCase() &&
				coin?.name?.toLowerCase() === name?.toLowerCase()
		);
		result = coin?.coingeckoid || null;
	}

	if (name && result === null) {
		const coin = coins.find(
			(coin: Coin) => coin?.name?.toLowerCase() === name?.toLowerCase()
		);
		result = coin?.coingeckoid || null;
	}

	if (symbol && result === null) {
		const coin = coins.find(
			(coin: Coin) =>
				coin?.symbol?.toLowerCase() === symbol?.toLowerCase()
		);
		result = coin?.coingeckoid || null;
	}

	return result;
};

export const formatDecimals = (value: LooseNumber): string => {
	const bigNumberValue = bn(value);
	let decimalPlaces = null;
	if (bigNumberValue.gte(0) && bigNumberValue.lt(10)) {
		decimalPlaces = 4;
	} else if (bigNumberValue.gte(10) && bigNumberValue.lt(100)) {
		decimalPlaces = 3;
	} else if (bigNumberValue.gte(100) && bigNumberValue.lt(1000)) {
		decimalPlaces = 2;
	} else if (bigNumberValue.gte(1000) && bigNumberValue.lt(10000)) {
		decimalPlaces = 1;
	} else {
		decimalPlaces = 0;
	}

	return bigNumberValue
		.toFixed(decimalPlaces, BigNumber.ROUND_HALF_UP)
		.replace(/\.?0+$/, "");
};

interface IRemoveZerosOptions {
	removeTrailing?: boolean;
	minDecimals?: number;
	maxDecimals?: number;
	displaySeparator?: boolean;
}
/**
 * @param {string} value
 * @param {boolean} [options={ removeLeading: true, removeTrailing: true, minimumDecimals: 0, displaySeparator: false }]
 * @return {string}  {string}
 * @example
 * const testNumber = "000001234567890000000";
 * const result = removeZeros(testNumber);
 * console.log(result); // "123456789"
 *
 * const testNumber2 = "0.1234567890000000";
 * const result2 = removeZeros(testNumber2);
 * console.log(result2); // "0.123456789"
 *
 * const testNumber3 = "00.1234567890000000";
 * const result3 = removeZeros(testNumber3);
 * console.log(result3); // "0.123456789"
 */
export const removeZeros = (
	value: LooseNumber,
	options?: IRemoveZerosOptions
): string => {
	value = bn(value).toFormat({
		groupSeparator: "",
		decimalSeparator: ".",
	});

	const defaultOpts = {
		removeTrailing: true,
		minDecimals: 0,
		displaySeparator: false,
	};
	const opts = {
		...defaultOpts,
		...options,
	};
	const {removeTrailing, minDecimals, maxDecimals} = opts;

	if (!value) return "";

	// remove leading zeros before decimal point
	value = value.replace(/^0+(?=\d\.)/, "0");

	// remove all leading zeros if there's no decimal point
	value = value.replace(/^0+/, "0");

	if (removeTrailing) {
		// remove trailing zeros after decimal point
		value = value.replace(/(\.\d*?[1-9])0+$/, "$1");

		// remove trailing decimal point only if there are no minimum decimals set
		if (minDecimals === 0) {
			value = value.replace(/\.0+$/, "");
		}
	}

	if (opts.displaySeparator) {
		value = bn(value).toFormat();
	}

	// ensure minimum number of decimals
	const decimals = value.includes(".") ? value.split(".")[1].length : 0;
	if (decimals < minDecimals) {
		value = value.includes(".")
			? value + "0".repeat(minDecimals - decimals)
			: value + "." + "0".repeat(minDecimals);
	}

	if (
		typeof maxDecimals !== "undefined" &&
		maxDecimals >= 0 &&
		maxDecimals >= minDecimals
	) {
		const includesDecimals = value.includes(".");
		const afterDecimal = includesDecimals ? value.split(".")[1] : "";
		const beforeDecimal = includesDecimals ? value.split(".")[0] : value;
		const decimals = value.includes(".") ? value.split(".")[1].length : 0;
		if (decimals > maxDecimals) {
			value = beforeDecimal + "." + afterDecimal.slice(0, maxDecimals);
		}
	}

	return value;
};

export const quillDeltaToText = (ops: Delta["ops"]): string => {
	if (!ops) return "";
	const delta = new Delta(ops);
	const text = delta
		.map((op) => {
			if (typeof op.insert === "string") {
				return op.insert;
			} else {
				return "";
			}
		})
		.join("");
	return text;
};

type MuiColor =
	| "primary"
	| "secondary"
	| "error"
	| "info"
	| "success"
	| "warning";

export const getMuiColorFromString = (str: string): MuiColor => {
	const colorArray: MuiColor[] = [
		"primary",
		"secondary",
		"error",
		"info",
		"success",
		"warning",
	];

	// Create an array of the colors to loop over

	// Create an alphabet object
	const alphabetObject: {[key: string]: MuiColor} = {};

	// Loop over the alphabet and apply the colors in consecutive order
	for (let i = 0; i < 26; i++) {
		// Get the current letter of the alphabet
		const letter: string = String.fromCharCode(97 + i);

		// Get the current color
		const color: MuiColor = colorArray[i % colorArray.length];

		// Add the letter and color to the alphabet object
		alphabetObject[letter] = color;
	}

	// Function that takes a string and selects from the alphabet object
	function getMuiColor(s: string): MuiColor | null {
		// Get the first letter of the string
		const firstLetter = s.charAt(0).toLowerCase();

		// Return the corresponding color, or null if the letter is not in the alphabet object
		return alphabetObject[firstLetter] || null;
	}

	// Get the color for the string
	return getMuiColor(str);
};

export const limitStringLength = (value: string, length: number) => {
	return typeof value === "string" && value.length > length
		? value.substring(0, length)
		: value;
};

export const isNull = (value = null) => {
	if (value === null) {
		return true;
	}
	return false;
};

export const isUndefined = (value = undefined) => {
	if (typeof value === typeof undefined || value === undefined) {
		return true;
	}
	return false;
};

export const isNun = <T>(value: T) => {
	if (isNull(value) || isUndefined(value)) {
		return true;
	}

	return false;
};

export const isVariable = (value = undefined) => {
	if (isNun(value)) {
		return false;
	}
	return true;
};

/**
 * @param {LooseNumber} value - eg 1000
 * @param {LooseNumber} fxRate eg 1.29 USD/GBP - price currency always numerator ie 1 GBP (denominator) buys 1.29 USD (numerator)
 * @param {number} [decimals=2] - number of decimal places to return - default 2.
 * @return {*}  {string}
 */
export const convertFxValue = (
	value: LooseNumber,
	fxRate: LooseNumber,
	decimals = 2
): string => {
	const bigNumberValue = bn(value);
	const bigNumberFxRate = bn(fxRate);
	const result = bigNumberValue
		.times(bigNumberFxRate)
		.toFixed(decimals, BigNumber.ROUND_HALF_UP);
	return result;
};

const Utils = {
	string: {
		toCamelCase,
		camelCaseToCapitalizedWithSpaces,
		keepValidEmailChars,
		removeNonAlpha,
		removeNonAlphaAllowHypenApostrophy,
		removeNonAlphaAllowHypenApostrophySpaces,
		isSafeStringWithNoNumbers,
		isSafeStringWithNoNumbersOrSpaces,
		getSafeString,
		sanitizeHTML,
		capitaliseSentenceExcludeUppercase,
		capitaliseSentence,
		capitaliseSentenceRegex,
		capitaliseStartOfSentence,
		capitaliseFirstLetterOfSentences,
		capitaliseWord,
		removeCommas,
		replaceUnderscore,
		addressShortener,
		replaceString,
		replaceNumbers,
		replaceSpaces,
		replaceSpecialCharacters,
		limitStringLength,
		toUpperCase,
	},
	number: {
		removeZeros,
		formatNumber,
		formatTokenValue,
		formatEthValue,
		percentFormat,
		percentDifference,
		discount,
		isOdd,
		formatUnit,
		bn,
		largestValueInArray,
	},
	currency: {
		formatCurrency,
		toMoneyString,
		getSymbolFromCurrency,
		convertFxValue,
	},
	array: {
		arraysMatch,
		reducer,
		sortByProps,
		uniqueObjectArrayByKeys,
		filterItems,
		largestValueInArray,
	},
	object: {
		pick,
		areObjectsEqual,
	},
	date: {
		dateFormat,
		convertValueToHMS,
		convertValueToMS,
	},
	data: {
		suuid,
		uuid,
		papaSaveDataAsCsv,
		saveDataAsCSV,
		saveDataAsExcel,
		setClipboard,
		quillDeltaToText,
	},
	url: {
		isEmail,
		isValidUrl,
		isValidTelegramHandle,
		convertTelegramHandleToLink,
		isValidLinkedInUrl,
		urlParamReplace,
		getBase64FromImageUrl,
		removeQueryString,
		addTransformToCloudinaryUrl,
	},
	coins: {
		getCoinGeckoId,
	},
	style: {
		getMuiColorFromString,
	},
	validation: {
		isNull,
		isUndefined,
		isNun,
		isVariable,
	},
	web3: {
		isAddress,
	},
	errorHandling: {
		transformError,
	},
};

export default Utils;

import { config, isDevelopment } from "Config/config";
const { WAGMI_CHAIN, INFURA_API_KEY, ETHEREUM_NETWORK } = config;
import { getPublicClient, getWalletClient } from "wagmi/actions";
import { createPublicClient, createWalletClient, custom, http, fallback, getContract as getContractInstance } from "viem";
import { erc20Abi, wethAbi } from "abitype/abis";
import { abi as otcABI } from "Blockchain/otc/artifacts/abi";
import { abi as splitterMinterAbi } from "Blockchain/splitterMinter/artifacts/SM_abi";
import { abi as sxnAbi } from "Blockchain/splitterMinter/artifacts/SXN_abi";

import { isNun } from "./utils";
import { asyncWrapper } from "App/src/js/utils/utils";

type Client = {
	wallet?: ReturnType<typeof createWalletClient>;
	public?: ReturnType<typeof createPublicClient>;
};

export const getClient = async () => {
	const client: Client = {
		wallet: undefined,
		public: undefined,
	};

	const wagmiPC = getPublicClient({ chainId: WAGMI_CHAIN.id });

	if (!wagmiPC) {
		throw new Error("Wagmi public client is required");
	}
	const transportRpcUrls = [http(`https://${ETHEREUM_NETWORK}.infura.io/v3/${INFURA_API_KEY}`), ...wagmiPC.transport.transports.map((t) => http(t.value.url))];

	const publicClient = createPublicClient({
		chain: WAGMI_CHAIN,
		transport: fallback(transportRpcUrls),
		batch: wagmiPC.batch,
		cacheTime: wagmiPC.cacheTime as number,
		key: wagmiPC.key,
		name: wagmiPC.name,
		pollingInterval: wagmiPC.pollingInterval,
	});

	client.public = publicClient;

	const [wagmiWC, wagmiWCError] = await asyncWrapper(getWalletClient({ chainId: WAGMI_CHAIN.id }));

	if (wagmiWCError || !wagmiWC) {
		throw new Error(isDevelopment() ? "Wagmi wallet client is required" : "Wallet not connected");
	}

	const { transport, account, key, name, cacheTime } = wagmiWC;
	const { request, type, retryCount, retryDelay, timeout } = transport;
	const walletClient = createWalletClient({
		chain: WAGMI_CHAIN,
		transport: custom({ key: transport.key, name: transport.name, request, type, retryCount, retryDelay, timeout }),
		account: account,
		key: key,
		name: name,
		cacheTime: cacheTime as number,
	});

	client.wallet = walletClient;

	return client;
};

const getContractBuilder = async <T extends ExtractFunctionParams<typeof getContractInstance>["address"], U extends ExtractFunctionParams<typeof getContractInstance>["abi"]>(address: T, abi: U) => {
	if (!address) throw new Error("Address is required");

	if (isNun(abi)) throw new Error("Abi is required");

	const [client, error] = await asyncWrapper(getClient());
	if (error) throw new Error("Error getting client");

	if (!client.public) throw new Error("Public client is required");

	const contract = getContractInstance({
		abi: abi,
		address: address,
		//eslint-disable-next-line
		//@ts-ignore
		walletClient: client?.wallet ? client.wallet : undefined,
		//eslint-disable-next-line
		// @ts-ignore
		publicClient: client.public,
	});

	return contract;
};

type GetERC20ContractProps = {
	address: HexString;
	abi?: typeof erc20Abi | typeof wethAbi;
};

export const getErc20Contract = async ({ address, abi = erc20Abi }: GetERC20ContractProps) => {
	const contract = await getContractBuilder(address, abi);
	return contract;
};

export const getWethContract = async () => {
	const contract = await getContractBuilder(config.ETHER_TOKEN_ADDRESS, wethAbi);
	return contract;
};

type GetOtcContractProps = {
	address: HexString;
};
export const getOtcContract = async ({ address }: GetOtcContractProps) => {
	const contract = await getContractBuilder(address, otcABI);
	return contract;
};

type GetSplitterMinterContractProps = {
	address: HexString;
};
export const getSplitterMinterContract = async ({ address }: GetSplitterMinterContractProps) => {
	const contract = await getContractBuilder(address, splitterMinterAbi);
	return contract;
};

type GetSxnContractProps = {
	address: HexString;
};

export const getSxnContract = async ({ address }: GetSxnContractProps) => {
	const contract = await getContractBuilder(address, sxnAbi);
	return contract;
};

export default getClient;

import BigNumber from "bignumber.js";

declare module "bignumber.js" {
	interface BigNumber {
		toInteger(): number;
		toBigInt(): bigint;
		fromFormat(formattedString: string, options?: BigNumber.Format): BigNumber;
	}
}

BigNumber.prototype.toInteger = function () {
	return this.integerValue(BigNumber.ROUND_FLOOR).toNumber();
};

BigNumber.prototype.toBigInt = function () {
	if (this.isNaN()) return BigInt(0);
	return BigInt(this.toFixed(0));
};

export const bn = (value: LooseNumberInt): BN => {
	if (typeof value === "bigint") return new BigNumber(value.toString());
	else return new BigNumber(value);
};

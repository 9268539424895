import { OxQuote } from "Types/api/Ox";
import { config } from "Config/config";
import { bn } from "Utils/utils";
const { Ox_ERC20PROXY_ADDRESS, ETHEREUM_CHAIN_ID, WAGMI_CHAIN } = config;
import { confirmationEvent, errorEvent, hashEvent } from "./events";
import { getAccount } from "wagmi/actions";
import { getClient } from "App/src/js/utils/viem";
import { asyncWrapper } from "Utils/utils";

const sendTransaction0x = async (quoteData: OxQuote) => {
	const { address: taker } = getAccount();
	const client = await getClient();

	const { gasPrice, gas, data, value } = quoteData;

	const [hash, hashError] = await asyncWrapper(
		client.wallet.sendTransaction({
			account: taker,
			to: Ox_ERC20PROXY_ADDRESS,
			value: bn(value).toBigInt(),
			data: data as HexString,
			chain: WAGMI_CHAIN,
			gasPrice: bn(gasPrice).toBigInt(),
			gas: ETHEREUM_CHAIN_ID == 5 ? bn(gas).times(1.5).toBigInt() : bn(gas).times(1).toBigInt(),
		}),
	);

	if (hashError) {
		console.log(hashError);
		window.dispatchEvent(errorEvent(hashError));
		return;
	}

	window.dispatchEvent(hashEvent(hash));

	const [receipt, receiptError] = await asyncWrapper(client.public.waitForTransactionReceipt({ hash }));

	if (receiptError) {
		console.error(receiptError);
		window.dispatchEvent(errorEvent(receiptError));
		return;
	}

	window.dispatchEvent(confirmationEvent(receipt));
};
export default sendTransaction0x;

import { withdrawHashEvent, withdrawConfirmationEvent, withdrawErrorEvent } from "./events";
import { bn } from "Utils/utils";
import { getAccount } from "wagmi/actions";
import getClient, { getWethContract } from "App/src/js/utils/viem";
import { asyncWrapper } from "App/src/js/utils/utils";
import type { TransactionReceipt } from "viem";

const unwrapEth = async (amount: string): Promise<TransactionReceipt> => {
	const { address: account } = getAccount();

	const client = await getClient();

	const contract = await getWethContract();

	const [simulation, simulationError] = await asyncWrapper(contract.simulate.withdraw([bn(amount).toBigInt()], { account }));

	if (simulationError) {
		window.dispatchEvent(withdrawErrorEvent(simulationError));
	}

	const [hash, hashError] = await asyncWrapper(contract.write.withdraw(simulation.request.args, { account }));

	if (hashError) {
		window.dispatchEvent(withdrawErrorEvent(hashError));
		return;
	}

	window.dispatchEvent(withdrawHashEvent(hash));

	const [receipt, receiptError] = await asyncWrapper(
		client.public.waitForTransactionReceipt({
			hash,
		}),
	);

	if (receiptError) {
		window.dispatchEvent(withdrawErrorEvent(receiptError));
		return;
	}

	window.dispatchEvent(withdrawConfirmationEvent(receipt));
	return receipt;
};
export default unwrapEth;

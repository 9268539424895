import { bn } from "Utils/utils";
import { getAccount } from "wagmi/actions";
import { getErc20Contract } from "App/src/js/utils/viem";

/**
 * @param {HexString} contractAddress - the spender of the tokens
 * @param {HexString} tokenAddress - the token to spend
 * @returns allowance value in wei
 */
const allowance = async (contractAddress: HexString, tokenAddress: HexString): Promise<string> => {
	const { address: account } = getAccount();
	const contract = await getErc20Contract({ address: tokenAddress });
	const _allowance = await contract.read.allowance([account, contractAddress], { account });
	const allowance = bn(_allowance).toFixed(0);
	return allowance;
};

export default allowance;

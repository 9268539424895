import { depositHashEvent, depositConfirmationEvent, depositErrorEvent } from "./events";
import { bn } from "Utils/utils";
import getClient, { getWethContract } from "App/src/js/utils/viem";
import { asyncWrapper } from "App/src/js/utils/utils";
import type { TransactionReceipt } from "viem";

const wrapEth = async (amount: string): Promise<TransactionReceipt> => {
	const client = await getClient();

	const contract = await getWethContract();

	const [simulation, simulationError] = await asyncWrapper(contract.simulate.deposit({ value: bn(amount).toBigInt() }));

	if (!!simulationError || !simulation) {
		window.dispatchEvent(depositErrorEvent(simulationError));
	}

	const [hash, hashError] = await asyncWrapper(contract.write.deposit({ value: bn(amount).toBigInt() }));

	if (hashError) {
		window.dispatchEvent(depositErrorEvent(hashError));
		return;
	}

	window.dispatchEvent(depositHashEvent(hash));

	const [receipt, receiptError] = await asyncWrapper(
		client.public.waitForTransactionReceipt({
			hash,
		}),
	);

	if (receiptError) {
		window.dispatchEvent(depositErrorEvent(receiptError));
		return;
	}

	window.dispatchEvent(depositConfirmationEvent(receipt));
	return receipt;
};

export default wrapEth;

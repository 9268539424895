import { config } from "Config/config";
const { Ox_API_BASE_URL, Ox_API_PATH_QUOTE, Ox_QUOTE_PARAMS } = config;
import type { OxQuote } from "Types/api/Ox";
import { bn } from "Utils/utils";
import useSWR from "swr";
import type { SWRConfiguration } from "swr";
import axios from "axios";

type TParamsBasics = {
	buyToken: string;
	sellToken: string;
	slippagePercentage?: number;
	gasPrice?: string;
	takerAddress?: string;
	excludedSources?: string;
	includeedSources?: string;
	skipValidation?: boolean;
	intentOnFilling?: boolean;
	feeRecipient?: string;
	buyTokenPercentageFee?: number;
	affiliateAddress?: string;
	enableSlippageProtection?: boolean;
	priceImpactProtectionPercentage: number;
};

interface IParamsWithBuyAmount extends TParamsBasics {
	buyAmount: string;
}

interface IParamsWithSellAmount extends TParamsBasics {
	sellAmount: string;
}

export type TParams = Either<IParamsWithBuyAmount, IParamsWithSellAmount>;

type TResult = {
	data: OxQuote;
	error: Error;
	loading: boolean;
};

export const zeroExBuyQuoteEventName = "buyQuoteUpdated" as const;
export const zeroExSellEventName = "sellQuoteUpdated" as const;

const buyQuoteEvent = (quote: OxQuote) => new CustomEvent(zeroExBuyQuoteEventName, { detail: quote });
const sellQuoteEvent = (quote: OxQuote) => new CustomEvent(zeroExSellEventName, { detail: quote });

const axiosFetcher = async (key: [TParams]) => {
	const { buyToken, sellToken, sellAmount, buyAmount } = key[0];
	const url = Ox_API_BASE_URL + Ox_API_PATH_QUOTE;

	if (buyToken && sellToken && sellAmount && bn(sellAmount).gt(0)) {
		const qp = {
			buyToken,
			sellToken,
			sellAmount,
		};
		return axios.get(url, { params: qp }).then((res) => {
			window.dispatchEvent(buyQuoteEvent(res.data));
			return res.data;
		});
	} else if (buyToken && sellToken && buyAmount && bn(buyAmount).gt(0)) {
		const qp = {
			buyToken,
			sellToken,
			buyAmount,
		};

		return axios.get(url, { params: qp }).then((res) => {
			window.dispatchEvent(sellQuoteEvent(res.data));
			return res.data;
		});
	} else {
		return Promise.reject("Invalid params");
	}
};

function use0xQuote(params?: TParams): TResult {
	const { buyToken, sellToken } = params || {};
	const qp = {
		buyToken: buyToken,
		sellToken: sellToken,
		buyTokenPercentageFee: Ox_QUOTE_PARAMS.buyTokenPercentageFee,
		feeRecipient: Ox_QUOTE_PARAMS.feeRecipient,
		...params,
	};
	const config: SWRConfiguration = {
		refreshInterval: 1000,
	};
	const { data, isLoading: loading, error } = useSWR([qp], axiosFetcher, config);

	return {
		data: data,
		error: error,
		loading: loading,
	};
}

export const getOxParams = (buyToken: string, sellToken: string, buyAmount?: NumStr, sellAmount?: NumStr, takerAddress?: string): TParams => {
	const params: Partial<TParams> = {
		buyToken,
		sellToken,
		takerAddress,
	};
	if (buyAmount) params.buyAmount = buyAmount.toString();
	if (sellAmount) params.sellAmount = sellAmount.toString();
	if (takerAddress) params.takerAddress = takerAddress;
	if (params.sellAmount && params.buyAmount) {
		delete params.buyAmount;
	}
	return params as TParams;
};

export default use0xQuote;
